
// import { icdf, cdf } from 'norm-dist'
// import pdf from 'norm-dist/pdf.js' //same as normDist.pdf

import unirand from 'unirand'

const π = Math.PI
const e = Math.E

function chooseRandomAngle(): number {
    return Math.random() * 2 * π
}

/**
 * Returns a random number from the uniform distribution [min, max)
 * ie. between min (inclusive) and max (exclusive)
 */
function chooseUniform(min:number, max: number) {
    return Math.random() * (max - min) + min;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function chooseUniformInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// function chooseNormal(mean = 0, std_dev = 1) : number {
//     // https://www.npmjs.com/package/distributions
// import * as distributions from 'distributions'

//     /*
//         pdf: probability density function - given x what is the probability of it occurring
//         cdf: cumulative density function -
//     */
//     let normal = distributions.Normal(mean, std_dev)
//     let rand = Math.random()  // uniform on [0, 1]
//     let p = normal.cdf(rand)
//     return p
// }

// let rng = null
// function chooseNormal(mean = 0, std_dev = 1): number {
//     if (!rng)
//     {
//         rng = unirand.normal()
//     }
// }

export function testNormal() {
    let mu = 100
    let sigma = 20
    let rng = unirand.normal(mu, sigma)
    for (var i = 0; i < 10; i++)
    {
        console.log('rng', rng.randomSync())
    }
}

interface xyPoint {
    x: number,
    y: number
}

/**
 * Starting with known point, proceed at an angle (in radians) for a distance and return the new point
 * Equivalent of adding a vector (direction and force) to a point
 */
function pointFromVector(point: xyPoint, angle: number, distance: number) : xyPoint
{
    // let O_A = Math.tan(angle)     // O/A
    let O_H = Math.sin(angle)
    let A_H = Math.cos(angle)

    let O = O_H * distance
    let A = A_H * distance

    let point2 = { x: point.x + A, y: point.y + O }
    return point2
}

export default {
    π: π,
    chooseRandomAngle: chooseRandomAngle,
    chooseUniform: chooseUniform,
    pointFromVector: pointFromVector
}