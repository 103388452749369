import { Scene } from "phaser";
import { Blob } from "./Blob";
import { NewBlobResult, StateCodes } from "./Hub";
import { gameWidth, game_theme, worldHeight, worldWidth } from "./start";
import { distanceBetweenTwoPoints } from "./Utils";

export class MainSceneHuman extends Scene {
  keyMap = {};
  players = [];
  scoresheet;
  totalArea;
  mainPlayerName;
  gameWidth;
  gameHeight;
  worldWidth;
  worldHeight;
  trailWidth;
  mainPlayerSpeed;
  speedOffset = 50;
  gameEnded = false;
  hub;
  sounds;
  dataFromWelcome;
  pausedState = false;
  requestedRestart = false;
  pointerMouse = { x: 0, y: 0 };
  sceneChanging = false;
  host = "";
  controlZoneMaxDistance = 190;
  controlZoneMaxTime = 40;
  controlZoneDt = 1;
  loaded = false;
  bonuses = [];

  constructor(gameWidth, gameHeight) {
    super("mainSceneHuman");
    this.gameWidth = gameWidth;
    this.gameHeight = gameHeight;
    this.worldWidth = worldWidth;
    this.worldHeight = worldHeight;
    this.trailWidth = 13;
    this.mainPlayerSpeed = 500;
  }

  init(data) {
    this.mainPlayerName = data.mainPlayerName;
    this.dataFromWelcome = data;
    this.hub = data.hub;

    this.loaded = false;
    this.hub.start(this.mainPlayerName ? this.mainPlayerName : "You");

    if (data.host) {
      this.host = data.host;
    }

    var zoom =
      Math.max(this.game.canvas.height, this.game.canvas.width) / gameWidth;
    this.cameras.main.setZoom(zoom);
  }

  create() {
    //  Set the camera and physics bounds to be the size of 4x4 bg images
    this.cameras.main.setBounds(0, 0, this.worldWidth, this.worldHeight);
    this.physics.world.setBounds(0, 0, this.worldWidth, this.worldHeight);

    this.totalArea = this.worldWidth * this.worldHeight;
    this.gameEnded = false;

    this.sounds = {
      kill: this.sound.add("kill"),
      background: this.sound.add("background", { loop: true, volume: 1 }),
      claim: this.sound.add("claim"),
      win: this.sound.add("win"),
      bonus: this.sound.add("bonus"),
    };

    if (this.dataFromWelcome.gameMusic) {
      this.sounds.background.play();
    }

    // launch scorescene and mainmenuscene in parallel
    this.scene.launch("scoresSceneHuman", this);
    this.scene.launch("mainMenuScene", this);
    this.sceneChanging = false;
    document.getElementById("injectToWelcome").style.display = "none";
    this.keyMap = {};
  }

  update() {
    // Check if connected or reload
    this.checkConnection();

    if (!this.loaded) {
      if (this.hub && this.hub.started) {
        this.loaded = true;
        document.getElementById("loadingAnimation").style.display = "none";
      }
    }

    if (!this.gameEnded && !this.pausedState) {
      // Update pos
      if (this.players.length > 0 && this.players[0].blob.isAlive) {
        this.hub.notify_pos(this.players[0].blob);
        if (
          Math.abs(this.players[0].sprite.x - this.players[0].circle.x) > 5 ||
          Math.abs(this.players[0].sprite.y - this.players[0].circle.y) > 5
        ) {
          this.players[0].circle.x = this.players[0].sprite.x;
          this.players[0].circle.y = this.players[0].sprite.y;
        }
      }

      if (this.players.length > 0) {
        if (this.players[0].blob.isAlive) {
          this.checkMovement();
          this.players[0].blob.trailAddPoint();
        }
      }

      this.players.forEach((player) => {
        if (player.blob.isAlive) {
          this.updatePlayerGraphics(player);
        }
      });

      //this.checkZoom();
      this.checkIfKilled();
    }
  }

  loadMainPlayer(message) {
    this.players.push({
      blob: new Blob(
        message.image,
        message.pos,
        this.mainPlayerName ? this.mainPlayerName : "You"
      ),
      sprite: this.physics.add.sprite(
        message.pos[0],
        message.pos[1],
        message.image.includes("png")
          ? message.image.replace(".png", "")
          : message.image.replace(".svg", "")
      ),
      trailGraphic: this.add.graphics(),
      claimedAreaGraphic: this.add.graphics(),
      trailColour: "0x" + message.colourx.slice(2),
      claimedAreaColour: "0x" + message.colourx.slice(2),
      keyboardUsed: true,
      circle: this.add.circle(message.pos[0], message.pos[1], 45, null, 0.2),
    });

    this.players[0].blob.updateClaimedArea(message.claim);
    this.players[0].blob.id = message.id;
    this.players[0].blob.score = message.score;
    this.players[0].blob.isMain = true;
    this.players[0].sprite.displayWidth = 50;
    this.players[0].sprite.scaleY = this.players[0].sprite.scaleX;
    this.players[0].sprite.setCollideWorldBounds(true);
    this.players[0].sprite.depth = 17;
    this.players[0].trailGraphic.depth = 14;
    this.players[0].claimedAreaGraphic.depth = 13;

    this.cameras.main.startFollow(this.players[0].sprite, true);

    this.players[0].circle.depth = 16;
    this.physics.add.existing(this.players[0].circle);
    this.players[0].circle.body.setCollideWorldBounds(true);

    window.onkeydown = window.onkeyup = (e) => {
      this.keyMap[e.key] = e.type === "keydown";
    };

    this.input.on("pointermove", (pointer) => {
      if (!this.sceneChanging) {
        this.players[0].keyboardUsed = false;

        var distance = distanceBetweenTwoPoints(
          this.players[0].sprite.x,
          this.players[0].sprite.y,
          pointer.worldX,
          pointer.worldY
        );

        if (distance < 48) {
          this.setVelocityXY(0);
        } else {
          this.physics.moveTo(
            this.players[0].sprite,
            pointer.worldX,
            pointer.worldY,
            this.mainPlayerSpeed *
              Math.min(distance / this.controlZoneMaxDistance, 1)
          );

          this.physics.moveTo(
            this.players[0].circle,
            pointer.worldX,
            pointer.worldY,
            this.mainPlayerSpeed *
              Math.min(distance / this.controlZoneMaxDistance, 1)
          );
        }
      }
    });

    //stop movement when leaving window
    this.input.on("gameout", () => {
      if (!this.sceneChanging) {
        var vX = this.players[0].sprite.body.velocity.x;
        var vY = this.players[0].sprite.body.velocity.y;
        this.time.addEvent({
          delay: 100,
          callback: () => {
            this.setVelocityX(vX * 0.75);
            this.setVelocityY(vY * 0.75);
          },
        });
        this.time.addEvent({
          delay: 200,
          callback: () => {
            this.setVelocityX(vX * 0.5);
            this.setVelocityY(vY * 0.5);
          },
        });
        this.time.addEvent({
          delay: 300,
          callback: () => {
            this.setVelocityX(vX * 0.25);
            this.setVelocityY(vY * 0.25);
          },
        });
        this.time.addEvent({
          delay: 500,
          callback: () => {
            this.setVelocityXY(0);
          },
        });
      }
    });
  }

  setupRemotePlayer(remote: NewBlobResult) {
    var newPlayer = {
      blob: new Blob(remote.image, remote.pos, remote.name),
      sprite: this.physics.add.sprite(
        remote.pos[0],
        remote.pos[1],
        remote.image.includes("png")
          ? remote.image.replace(".png", "")
          : remote.image.replace(".svg", "")
      ),
      trailGraphic: this.add.graphics(),
      claimedAreaGraphic: this.add.graphics(),
      trailColour: "0x" + remote.colourx.slice(2),
      claimedAreaColour: "0x" + remote.colourx.slice(2),
      nameText: this.make.text({
        x: remote.pos[0],
        y: remote.pos[1] + 45,
        text: remote.name.toUpperCase(),
        style: {
          font: "23px Teko",
          color: "#ffffff",
        },
      }),
    };

    newPlayer.blob.id = remote.id;
    newPlayer.blob.trailPoints = remote.trail;
    newPlayer.blob.score = remote.score;
    newPlayer.blob.updateClaimedArea(remote.claim);
    newPlayer.blob.firstUpdate = true;
    newPlayer.sprite.displayWidth = 50;
    newPlayer.sprite.scaleY = newPlayer.sprite.scaleX;
    newPlayer.sprite.setCollideWorldBounds(true);
    newPlayer.sprite.depth = 15;
    newPlayer.trailGraphic.depth = 14;
    newPlayer.claimedAreaGraphic.depth = 13;

    newPlayer.nameText.depth = 16;
    newPlayer.nameText.setOrigin(0.5);

    newPlayer.blob.isAlive = !remote.killed.is_killed;
    newPlayer.blob.killedBy = remote.killed.by;
    newPlayer.blob.isAi = remote.is_ai;

    this.players.push(newPlayer);
  }

  removePlayer(player) {
    var gameData = {
      mainPlayerName: this.mainPlayerName,
      players: this.players,
      totalArea: this.totalArea,
      gameMusic: this.dataFromWelcome.gameMusic,
      gameSounds: this.dataFromWelcome.gameSounds,
      host: this.host || this.dataFromWelcome.host,
      hub: this.hub,
      killedBy: null,
    };

    let playerToRemove = this.players.find((p) => {
      return p.blob.id == player.id;
    });

    if (playerToRemove) {
      playerToRemove.blob.killedBy = player.killed_by_name;
      if (this.players[0].blob.id === playerToRemove.blob.id) {
        gameData.killedBy = playerToRemove.blob.killedBy;
        playerToRemove.blob.isAlive = false;
        this.cameras.main.stopFollow();
        this.sceneChanging = true;
        this.players[0].circle.destroy();
        this.time.addEvent({
          delay: 1000,
          callback: () => {
            this.sounds.background.stop();
            this.scene.stop("scoresSceneHuman");
            this.scene.stop("mainMenuScene");
            this.hub.close_websocket();
            this.scene.start("endSceneHuman", { ...gameData, win: false });
          },
        });

        if (this.dataFromWelcome.gameSounds) {
          this.sounds.kill.play();
        }
      } else {
        this.time.addEvent({
          delay: 20000,
          callback: () => {
            playerToRemove.blob.showPlayerScore = false;
            var newPlayers = this.players.filter(
              (p) => p.blob.id !== playerToRemove.blob.id
            );
            this.players = newPlayers;
          },
        });

        playerToRemove.blob.isAlive = false;
        playerToRemove.trailGraphic.clear();
        playerToRemove.claimedAreaGraphic.clear();
        if (this.dataFromWelcome.gameSounds) {
          this.sounds.kill.play();
        }
      }
    } else {
      console.log(`Player to remove not found: ${player.id}`);
    }
  }

  spawnBonus(bonus) {
    var index = Math.floor(Math.random() * game_theme.bonuses.points.length);
    var theme = game_theme.bonuses.points[index].name;
    var displayWidth = game_theme.bonuses.points[index].size;

    switch (bonus.type) {
      case "SPOOK":
        index = Math.floor(Math.random() * game_theme.bonuses.spook.length);
        theme = game_theme.bonuses.spook[index].name;
        displayWidth = game_theme.bonuses.spook[index].size;
        break;
    }

    var b = {
      sprite: this.physics.add.sprite(bonus.point[0], bonus.point[1], theme),
      name: bonus.name,
      type: bonus.type,
      id: bonus.id,
      point: bonus.point,
      tween: null,
    };

    b.sprite.displayWidth = displayWidth;
    b.sprite.scaleY = b.sprite.scaleX;
    b.sprite.depth = 15;

    b.tween = this.tweens.add({
      targets: b.sprite,
      scaleX: b.sprite.scaleX * 1.3,
      scaleY: b.sprite.scaleY * 1.3,
      ease: "Sine.easeInOut",
      duration: 350,
      repeat: -1,
      yoyo: true,
    });
    this.bonuses.push(b);
  }

  updateBonus(bonus) {
    let bonusToRemove = this.bonuses.find((b) => {
      return b.id === bonus.collected_bonus_id;
    });

    let player = this.players.find((p) => {
      return p.blob.id === bonus.collected_player_id;
    });

    if (bonusToRemove && player) {
      this.physics.moveTo(
        bonusToRemove.sprite,
        player.sprite.x,
        player.sprite.y,
        1250
      );
      if (this.players[0].blob.id === bonus.collected_player_id) {
        if (this.dataFromWelcome.gameSounds) {
          this.sounds.bonus.play();
        }

        // Bonus collected text animation
        var bonusType = "";

        switch (bonusToRemove.type) {
          case "POINTS":
            bonusType = "+20k";
            break;
        }

        var bonusText = this.make.text({
          x: bonusToRemove.sprite.x,
          y: bonusToRemove.sprite.y,
          text: bonusType,
          style: {
            font: "26px Teko",
            color: "#FFD700",
          },
        });

        bonusText.depth = 16;
        bonusText.setShadow(2, 2, "#B92200", 2, true, true);
        this.physics.add.existing(bonusText);

        this.time.addEvent({
          delay: 400,
          callback: () => {
            this.physics.moveTo(
              bonusText,
              this.cameras.main.x,
              this.cameras.main.y + 150,
              1500
            );
          },
        });

        this.time.addEvent({
          delay: 2000,
          callback: () => {
            bonusText.destroy();
          },
        });
      }

      this.time.addEvent({
        delay: 150,
        callback: () => {
          bonusToRemove.sprite.destroy();
          this.bonuses = this.bonuses.filter(
            (b) => b.id !== bonus.collected_bonus_id
          );
        },
      });

      this.spawnBonus(bonus.new_bonus);
    }
  }

  createWin(player) {
    var gameData = {
      mainPlayerName: this.mainPlayerName,
      players: this.players,
      totalArea: this.totalArea,
      gameMusic: this.dataFromWelcome.gameMusic,
      gameSounds: this.dataFromWelcome.gameSounds,
      host: this.host || this.dataFromWelcome.host,
      hub: this.hub,
    };

    this.gameEnded = true;

    if (this.players[0].blob.id === player.blob.id) {
      this.sounds.win.play();
      this.time.addEvent({
        delay: 1000,
        callback: () => {
          this.sounds.background.stop();
          this.scene.stop("scoresSceneHuman");
          this.scene.stop("mainMenuScene");
          this.hub.close_websocket();
          this.scene.start("endSceneHuman", { ...gameData, win: true });
        },
      });
    } else {
      this.time.addEvent({
        delay: 1000,
        callback: () => {
          this.sounds.background.stop();
          this.scene.stop("scoresSceneHuman");
          this.scene.stop("mainMenuScene");
          this.hub.close_websocket();
          this.scene.start("endSceneHuman", { ...gameData, win: false });
        },
      });
      if (this.dataFromWelcome.gameSounds) {
        this.sounds.kill.play();
      }
    }
    this.sceneChanging = true;
  }

  updatePlayerGraphics(player) {
    if (this.players[0] != player && player.blob.isAlive) {
      player.nameText.x = player.sprite.x;
      player.nameText.y = player.sprite.y + 45;
    }

    player.sprite.angle += 1;
    player.trailGraphic.clear();
    player.trailGraphic.lineStyle(this.trailWidth, player.trailColour, 0.5);

    player.blob.path.draw(player.trailGraphic);
    player.blob.path.lineTo(player.sprite.x, player.sprite.y);

    if (player.blob.firstUpdate && player.blob.trailPoints.length > 1) {
      player.blob.path = new Phaser.Curves.Path(
        player.blob.trailPoints[0][0],
        player.blob.trailPoints[0][1]
      );
      for (let t of player.blob.trailPoints) {
        player.blob.path.draw(player.trailGraphic);
        player.blob.path.lineTo(t[0], t[1]);
      }
      player.blob.firstUpdate = false;
    }

    player.claimedAreaGraphic.clear();
    player.claimedAreaGraphic.lineStyle(5, player.claimedAreaColour, 1.0);
    player.claimedAreaGraphic.fillStyle(player.claimedAreaColour, 1.0);
    for (let poly of player.blob.claimedArea) {
      player.claimedAreaGraphic.fillPoints(poly.points, true);
    }
  }

  checkIfKilled() {
    // run animation if killed and destroy sprite
    for (let player of this.players) {
      if (!player.blob.isAlive && !player.blob.killAnimationComplete) {
        player.sprite.displayWidth += 20;
        player.sprite.scaleY = player.sprite.scaleX;
        player.sprite.alpha -= 0.015;
        player.sprite.angle -= 10;

        if (player.nameText) {
          player.nameText.displayWidth += 15;
          player.nameText.scaleY = player.nameText.scaleX;
          player.nameText.alpha -= 0.015;
        }
        player.blob.killDt += 1;

        if (player.blob.killDt > 100) {
          player.blob.killAnimationComplete = true;
          player.sprite.destroy();
          if (player.nameText) {
            player.nameText.destroy();
          }
        }
      }
    }
  }

  checkConnection() {
    if (this.hub.stateCode === StateCodes.CONNECTION_OPEN) {
      if (this.pausedState) {
        this.pausedState = false;
      }
    }

    if (this.hub.stateCode === StateCodes.CONNECTION_ERROR) {
      if (!this.pausedState) {
        this.pausedState = true;
      }
    }

    if (
      (this.hub.stateCode === StateCodes.CONNECTION_DIED ||
        this.hub.stateCode === StateCodes.SERVER_FULL) &&
      !this.requestedRestart
    ) {
      if (!this.pausedState) {
        this.pausedState = true;
      }
      this.requestedRestart = true;

      var resetText = this.make.text({
        x: this.cameras.main.scrollX + this.game.canvas.width / 2 - 180, //scrollX is current top left of camera position
        y: this.cameras.main.scrollY + this.game.canvas.height / 2 - 60,
        text: StateCodes.SERVER_FULL
          ? "Server is full. Please try again later ....".toUpperCase()
          : "Lost connection to server, restarting now ....".toUpperCase(),
        style: {
          font: "28px Teko",
          color: "#ffffff",
        },
      });
      resetText.depth = 99;

      this.time.addEvent({
        delay: 2000,
        callback: () => {
          this.resetGame();
        },
      });
    }
  }

  checkMovement() {
    // Keyboard movement: Normal Player
    if (this.players[0].keyboardUsed) {
      this.setVelocityXY(0);
    }

    if (this.keyMap["a"] || this.keyMap["ArrowLeft"]) {
      this.controlZoneDt++;
      this.players[0].keyboardUsed = true;
      this.setVelocityX(
        (-this.mainPlayerSpeed + this.speedOffset) *
          Math.min(1, this.controlZoneDt / this.controlZoneMaxTime)
      );
    } else if (this.keyMap["d"] || this.keyMap["ArrowRight"]) {
      this.controlZoneDt++;
      this.players[0].keyboardUsed = true;
      this.setVelocityX(
        (this.mainPlayerSpeed - this.speedOffset) *
          Math.min(1, this.controlZoneDt / this.controlZoneMaxTime)
      );
    }

    if (this.keyMap["w"] || this.keyMap["ArrowUp"]) {
      this.controlZoneDt++;
      this.players[0].keyboardUsed = true;
      this.setVelocityY(
        (-this.mainPlayerSpeed + this.speedOffset) *
          Math.min(1, this.controlZoneDt / this.controlZoneMaxTime)
      );
    } else if (this.keyMap["s"] || this.keyMap["ArrowDown"]) {
      this.controlZoneDt++;
      this.players[0].keyboardUsed = true;
      this.setVelocityY(
        (this.mainPlayerSpeed - this.speedOffset) *
          Math.min(1, this.controlZoneDt / this.controlZoneMaxTime)
      );
    }

    if (
      !this.keyMap["a"] &&
      !this.keyMap["ArrowLeft"] &&
      !this.keyMap["d"] &&
      !this.keyMap["ArrowRight"] &&
      !this.keyMap["w"] &&
      !this.keyMap["ArrowUp"] &&
      !this.keyMap["s"] &&
      !this.keyMap["ArrowDown"]
    ) {
      this.controlZoneDt = 1;
    }

    // Update player position
    this.players[0].blob.position = [
      this.players[0].sprite.x,
      this.players[0].sprite.y,
    ];
  }

  setVelocityXY(velocity: number) {
    if (this.players[0].sprite) {
      this.players[0].sprite.setVelocity(velocity);
      if (this.players[0].circle.body) {
        this.players[0].circle.body.velocity.x = velocity;
        this.players[0].circle.body.velocity.y = velocity;
      }
    }
  }

  setVelocityY(velocity: number) {
    if (this.players[0].sprite) {
      this.players[0].sprite.setVelocityY(velocity);
      if (this.players[0].circle.body) {
        this.players[0].circle.body.velocity.y = velocity;
      }
    }
  }

  setVelocityX(velocity: number) {
    if (this.players[0].sprite) {
      this.players[0].sprite.setVelocityX(velocity);
      if (this.players[0].circle.body) {
        this.players[0].circle.body.velocity.x = velocity;
      }
    }
  }

  playClaimSound() {
    if (this.dataFromWelcome.gameSounds) {
      this.sounds.claim.play();
    }
  }

  clearScene() {
    this.players = [];
    this.scoresheet = "";
    this.hub = null;
    this.host = "";
  }

  resetGame() {
    window.location.reload();
  }

  getSounds() {
    return {
      gameSounds: this.dataFromWelcome.gameSounds,
      gameMusic: this.dataFromWelcome.gameMusic,
    };
  }

  toggleGameSound() {
    this.dataFromWelcome.gameSounds = !this.dataFromWelcome.gameSounds;
    window.localStorage.setItem("gameSounds", this.dataFromWelcome.gameSounds);
  }

  toggleGameMusic() {
    if (this.dataFromWelcome.gameMusic) {
      this.dataFromWelcome.gameMusic = false;
      this.sounds.background.stop();
    } else {
      this.dataFromWelcome.gameMusic = true;
      this.sounds.background.play();
    }
    window.localStorage.setItem("gameMusic", this.dataFromWelcome.gameMusic);
  }

  forceEndScene() {
    var gameData = {
      mainPlayerName: this.mainPlayerName,
      players: this.players,
      totalArea: this.totalArea,
      gameMusic: this.dataFromWelcome.gameMusic,
      gameSounds: this.dataFromWelcome.gameSounds,
      host: this.host || this.dataFromWelcome.host,
      hub: this.hub,
    };

    this.time.addEvent({
      delay: 200,
      callback: () => {
        this.sounds.background.stop();
        this.scene.stop("scoresSceneHuman");
        this.scene.stop("mainMenuScene");
        this.hub.close_websocket();
        this.scene.start("endSceneHuman", { ...gameData, win: false });
      },
    });
  }
}
