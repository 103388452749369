import { Scene } from "phaser";
import { mainSceneAI, welcomeScene } from "./start";
import { abbreviateNumber } from "./Utils";

export class EndSceneAI extends Scene {
  end;
  sounds;
  dataFromMain;
  injectTo;
  injected = false;
  sceneData;
  isOpen;
  buttons;
  leaderboards;
  leaderboardsUpdated = false;
  controllerHost;

  constructor() {
    super("endSceneAI");
    this.controllerHost =
      process.env.NODE_ENV === "production"
        ? process.env.HOST
        : "http://0.0.0.0:5001";
  }

  init(data) {
    this.dataFromMain = data;
  }

  async create() {
    this.injectTo = document.getElementById("injectToEndPage");
    this.injected = false;

    await fetch("html/End.html")
      .then((response) => response.text())
      .then((text) => {
        this.injectTo.innerHTML = text;
        this.injected = true;
        this.injectTo.style.display = "flex";
        this.injectTo.style.visibility = "visible";
        this.injectTo.style.transform = "scale(1)";
      });

    if (this.dataFromMain.players[0].blob.killedBy) {
      document.getElementById(
        "endPageTitle"
      ).innerHTML = `Game Over! <br><br> You've been killed by ${this.dataFromMain.players[0].blob.killedBy} :(`;
    }

    if (this.dataFromMain.win) {
      document.getElementById("endPageTitle").innerText =
        "Congratulations! You Win!";
    }

    this.updateScores();

    this.isOpen = {
      leaderboardOpen: false,
    };

    this.buttons = {
      leaderboardButton: null,
      closeButtonLeaderboard: null,
    };

    this.addListeners();

    fetch(this.controllerHost + "/leaderboards")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.leaderboards = data;
        this.leaderboardsUpdated = true;
        console.log("Retrieved leaderboards");
      })
      .catch((error) => console.log(error));

    this.sounds = {
      background: this.sound.add("background", { loop: true }),
      lose: this.sound.add("lose"),
    };

    if (this.dataFromMain.win && this.dataFromMain.gameMusic) {
      this.sounds.background.play();
    } else if (this.dataFromMain.gameMusic) {
      this.sounds.lose.play();
    }

    var goBackToRoomButton = document.getElementById("goBackToRoomButton");
    goBackToRoomButton.style.display = "none";
  }

  update() {
    if (this.injected) {
      if (
        this.leaderboards &&
        this.leaderboards.monthly.length > 0 &&
        this.leaderboardsUpdated
      ) {
        var monthly = "";
        var weekly = "";
        var daily = "";

        this.leaderboards.monthly.forEach((p) => {
          monthly += `<div class="leaderboardText">${
            p.name
          }:&nbsp;&nbsp;&nbsp;&nbsp;${abbreviateNumber(p.score)}</div>`;
        });

        this.leaderboards.weekly.forEach((p) => {
          weekly += `<div class="leaderboardText">${
            p.name
          }:&nbsp;&nbsp;&nbsp;&nbsp;${abbreviateNumber(p.score)}</div>`;
        });

        this.leaderboards.daily.forEach((p) => {
          daily += `<div class="leaderboardText">${
            p.name
          }:&nbsp;&nbsp;&nbsp;&nbsp;${abbreviateNumber(p.score)}</div>`;
        });

        document.querySelector("#monthlyLeaderboardEnd").innerHTML = monthly;
        document.querySelector("#weeklyLeaderboardEnd").innerHTML = weekly;
        document.querySelector("#dailyLeaderboardEnd").innerHTML = daily;

        this.leaderboardsUpdated = false;
      }
    }
  }

  addListeners() {
    var practiceAgainButton = document.getElementById("practiceAgainButton");
    practiceAgainButton.addEventListener("click", () => this.onPlay());

    var goToStartAgainButton = document.getElementById("goToStartButton");
    goToStartAgainButton.addEventListener("click", () => this.onGoToStart());

    this.buttons.leaderboardButton = document.getElementById(
      "leaderboardButtonEnd"
    );
    this.buttons.leaderboardButton.addEventListener("click", () =>
      this.onClickLeaderboard()
    );

    this.buttons.closeLeaderboardButton = document.getElementById(
      "closeButtonLeaderboardEnd"
    );
    this.buttons.closeLeaderboardButton.addEventListener("click", () =>
      this.onClickLeaderboard()
    );
  }

  removeListeners() {
    var practiceAgainButton = document.getElementById("practiceAgainButton");
    practiceAgainButton.removeEventListener("click", () => this.onPlay());

    var goToStartAgainButton = document.getElementById("goToStartButton");
    goToStartAgainButton.removeEventListener("click", () => this.onGoToStart());

    this.buttons.leaderboardButton.removeEventListener("click", () =>
      this.onClickLeaderboard()
    );
    this.buttons.closeLeaderboardButton.removeEventListener("click", () =>
      this.onClickLeaderboard()
    );
  }

  onClickLeaderboard() {
    var doc = document.getElementById("leaderboardLightBoxEnd");
    if (this.isOpen.leaderboardOpen) {
      doc.style.visibility = "hidden";
      doc.style.transform = "scale(0)";
      this.isOpen.leaderboardOpen = false;
    } else if (!this.isOpen.leaderboardOpen) {
      doc.style.visibility = "visible";
      doc.style.transform = "scale(1)";
      this.isOpen.leaderboardOpen = true;
    }
  }

  onPlay() {
    this.sceneData = {
      gameMusic: this.dataFromMain.gameMusic,
      gameSounds: this.dataFromMain.gameSounds,
      mainPlayerName: this.dataFromMain.mainPlayerName,
      playersAI: this.dataFromMain.playersAI,
      difficulty: this.dataFromMain.difficulty,
    };

    this.sounds.background.stop();
    this.sounds.lose.stop();
    this.removeListeners();
    mainSceneAI.clearScene();
    this.injectTo.style.visibility = "hidden";
    this.injectTo.style.transform = "scale(0)";
    this.scene.start("mainSceneAI", this.sceneData);
    this.scene.stop();
  }

  onGoToStart() {
    this.sceneData = {
      gameMusic: this.dataFromMain.gameMusic,
      gameSounds: this.dataFromMain.gameSounds,
      mainPlayerName: this.dataFromMain.mainPlayerName,
    };
    this.sounds.background.stop();
    this.sounds.lose.stop();
    this.removeListeners();
    mainSceneAI.clearScene();
    welcomeScene.scene.restart(this.sceneData);
    this.scene.stop();
  }

  updateScores() {
    var sortedPlayers = [];
    this.dataFromMain.players.forEach((player) => {
      sortedPlayers.push({
        name: player.blob.name,
        area: player.blob.calculateArea(),
        killedBy: player.blob.killedBy,
        blob: player.blob,
      });
    });

    // Sort => player with biggest area is first
    // var playersKilled = sortedPlayers.filter((player) => player.killedBy);
    // var playersAlive = sortedPlayers.filter((player) => !player.killedBy);
    // playersAlive = playersAlive.sort((a, b) => (a.area < b.area ? 1 : -1));
    // sortedPlayers = playersAlive.concat(playersKilled);
    sortedPlayers = sortedPlayers.sort((a, b) => (a.area < b.area ? 1 : -1));

    var text = '<div id="scoreBoxLast">';

    text += `<h3 id="headingLast">Scoreboard</h3>`;

    sortedPlayers.forEach((player) => {
      var progress = (player.area / this.dataFromMain.totalArea) * 100;
      var abbreviated = abbreviateNumber(player.area);
      var abbreviatedLastScore = abbreviateNumber(player.blob.finalScore);

      if (player.killedBy) {
        text += `<div id="playerLast"> 
                            <div id="${
                              player.blob.isAI
                                ? "progressLast"
                                : "progressMainLast"
                            }" style="width:${progress}%"> </div> 
                            <div id="textContainerLast">
                                <span id="${
                                  player.blob.isAI ? "textLast" : "textMainLast"
                                }">${
          player.name
        }${"  - "}${abbreviatedLastScore}${"   -   Killed by "}${
          player.killedBy
        }</span>
                            </div>
                        </div>`;
      } else {
        text += `<div id="playerLast">
                            <div id="${
                              player.blob.isAI
                                ? "progressLast"
                                : "progressMainLast"
                            }" style="width:${progress}%"></div>
                            <div id="textContainerLast">
                                <span id="${
                                  player.blob.isAI ? "textLast" : "textMainLast"
                                }">${
          player.name
        }${"   -   "}${abbreviated}</span>
                            </div>
                        </div>`;
      }
    });

    text += `</div>`;

    document.querySelector("#highscoreLast").innerHTML = text;
  }
}
