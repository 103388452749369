import { Scene } from "phaser";
import { differenceInDates } from "./Utils";

export class CookieScene extends Scene {
  injectTo;
  injected = false;
  buttons;
  cookieConsent;

  constructor() {
    super("cookieScene");
  }

  async create() {
    this.cookieConsent = window.localStorage.getItem("cookie_consent");
    if (
      !this.cookieConsent ||
      differenceInDates(new Date(), new Date(this.cookieConsent)) > 30
    ) {
      this.injectTo = document.getElementById("injectToCookie");
      this.injected = false;
      await fetch("html/CookieBanner.html")
        .then((response) => response.text())
        .then((text) => {
          this.injectTo.innerHTML = text;
          this.injectTo.style.display = "flex";
          this.injected = true;
        });

      this.buttons = {
        cookieAgreeButton: null,
      };
      this.addListeners();
    }

    this.scene.launch("preloadScene");

    if (
      this.cookieConsent &&
      differenceInDates(new Date(), new Date(this.cookieConsent)) <= 30
    ) {
      this.scene.stop();
    }
  }

  addListeners() {
    this.buttons.cookieAgreeButton =
      document.getElementById("cookieAgreeButton");
    this.buttons.cookieAgreeButton.addEventListener("click", () => {
      this.onAgree();
    });
  }

  removeListeners() {
    this.buttons.cookieAgreeButton.removeEventListener("click", () => {
      this.onAgree();
    });
  }

  onAgree() {
    window.localStorage.setItem("cookie_consent", new Date().toUTCString());
    this.injectTo.style.display = "none";
    this.removeListeners();
    this.scene.stop();
  }
}
