import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export class Firebase {

    initialise() {

        const liveConfig = {
            apiKey: "AIzaSyBUczcVGBUxdb0T0MF2KBt6a8up-aLqm1U",
            authDomain: "fruit-loop-4d10d.firebaseapp.com",
            projectId: "fruit-loop-4d10d",
            storageBucket: "fruit-loop-4d10d.appspot.com",
            messagingSenderId: "1096108483367",
            appId: "1:1096108483367:web:54286540e985e01ed17f02",
            measurementId: "G-0BKV7JPNFY"
        };

        // Initialize Firebase
        const app = initializeApp(liveConfig);

        // Initialize Analytics
        getAnalytics(app);
    }
}