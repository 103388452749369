import { Scene } from "phaser";
import { mainSceneAI, mainSceneHuman } from "./start";

export class MainMenuScene extends Scene {
  injectTo;
  injected = false;
  menuOpen = false;
  buttons;
  currentScene: any = null;

  constructor() {
    super("mainMenuScene");
  }

  init(data) {
    if (data.aiScene) {
      this.currentScene = mainSceneAI;
    } else {
      this.currentScene = mainSceneHuman;
    }
    this.menuOpen = false;
  }

  async create() {
    this.injectTo = document.getElementById("injectToMenu");
    this.injected = false;
    await fetch("html/MainMenu.html")
      .then((response) => response.text())
      .then((text) => {
        this.injectTo.innerHTML = text;
        this.injectTo.style.display = "flex";
        this.injected = true;
      });
    this.events.on("shutdown", this.onStop);

    this.buttons = {
      menuButton: null,
      closeMenuButton: null,
      endGameButton: null,
      gameMusic: null,
      gameSounds: null,
    };

    this.addListeners();
  }

  addListeners() {
    var sounds = this.currentScene.getSounds();
    this.buttons.menuButton = document.getElementById("menuButton");
    this.buttons.menuButton.addEventListener("click", () =>
      this.onClickMenuButton()
    );

    this.buttons.closeMenuButton = document.getElementById("closeButtonMenu");
    this.buttons.closeMenuButton.addEventListener("click", () =>
      this.onClickMenuButton()
    );

    this.buttons.gameMusic = document.getElementById("menuGameMusicCheckbox");
    this.buttons.gameMusic.checked = sounds.gameMusic;
    this.buttons.gameMusic.addEventListener("click", () =>
      this.onClickMenuGameMusic()
    );

    this.buttons.gameSounds = document.getElementById("menuGameSoundsCheckbox");
    this.buttons.gameSounds.checked = sounds.gameSounds;
    this.buttons.gameSounds.addEventListener("click", () =>
      this.onClickMenuGameSounds()
    );

    this.buttons.endGameButton = document.getElementById("menuEndGameButton");
    this.buttons.endGameButton.addEventListener("click", () =>
      this.onClickEndGame()
    );
  }

  onClickMenuButton() {
    var doc = document.getElementById("menuLightBox");
    var button = document.getElementById("menuButton");
    if (this.menuOpen) {
      doc.style.transform = "translateX(100%)";
      this.time.addEvent({
        delay: 200,
        callback: () => {
          doc.style.transform = "translateX(0)";
          doc.style.display = "none";
          button.style.display = "flex";
          this.menuOpen = false;
        },
      });
    } else if (!this.menuOpen) {
      button.style.transform = "translateX(100%)";
      this.time.addEvent({
        delay: 200,
        callback: () => {
          button.style.transform = "translateX(0)";
          button.style.display = "none";
          doc.style.display = "flex";
          this.menuOpen = true;
        },
      });
    }
  }

  onClickMenuGameMusic() {
    this.currentScene.toggleGameMusic();
  }

  onClickMenuGameSounds() {
    this.currentScene.toggleGameSound();
  }

  onClickEndGame() {
    this.currentScene.forceEndScene();
  }

  onStop() {
    var doc = document.getElementById("mainMenu");
    doc.style.visibility = "hidden";
    doc.style.transform = "translateX(200px)";
  }
}
