import { Scene } from "phaser";
import Back from "back";
import { Blob } from "./Blob";

let wss: WebSocket = null;
let attempt = null;

export enum StateCodes {
  AWAITING,
  CONNECTION_OPEN,
  CONNECTION_ERROR,
  CONNECTION_CLOSED_CLEAN,
  CONNECTION_DIED,
  SERVER_FULL,
}

export class Hub {
  name: string = "I am a hub";
  paused: boolean = false;
  log: string[] = [];
  state: string = "";
  stateCode: StateCodes = StateCodes.AWAITING;
  //drawing: Drawing;
  world: any;
  wss_retry_url: string;
  started = false;

  server_id; // To change to Guid
  docker_id;
  room_id;
  connections: number = 0;
  leaderboards: LeaderBoardResult;
  leaderboardsUpdated = false;

  started_callbacks: ((data: StartedResult) => any)[] = [];
  update_callbacks: ((data: UpdatedResult) => any)[] = [];

  remotejoin_callbacks: ((data: NewBlobResult) => any)[] = [];
  position_callbacks: ((data: UpdateBlobResult[]) => any)[] = [];
  claim_callbacks: ((data: Claim) => any)[] = [];
  kill_callbacks: ((data: Kill) => any)[] = [];

  constructor(world: Scene, log: string[]) {
    this.world = world;
    this.log = log;
    this.started = false;
  }

  async notify_pos(blob: Blob) {
    let msg = {
      t: "pos",
      id: blob.id,
      name: blob.name,
      pos: [blob.position[0], blob.position[1]],
      room_id: this.room_id,
    };
    await wss.send(JSON.stringify(msg));
  }

  async notify_leaderboard() {
    await wss.send(JSON.stringify({ t: "leaderboard" }));
  }

  async start(name: string) {
    let msg = { t: "start", name: name };
    await wss.send(JSON.stringify(msg));
  }

  async close_websocket() {
    await wss.close();
  }

  onreceive(event) {
    let data = JSON.parse(event.data);
    switch (data.t) {
      case "connected":
        let connected = data.d as ConnectedResult;
        if (this.server_id && this.server_id !== connected.server_id) {
          // Server has rebooted
          this.world.resetGame();
        }
        this.server_id = connected.server_id;
        this.docker_id = connected.docker_id;
        this.connections = connected.connections;
        this.leaderboards = connected.leaderboards as LeaderBoardResult;
        this.leaderboardsUpdated = true;
        break;

      case "started":
        let started = data.d as StartedResult;
        //console.log("STARTED:", started);

        let ok = started.ok;

        if (ok) {
          this.room_id = started.room_id;
          if (started.you) {
            this.world.loadMainPlayer(started.you);
          }
          for (let p of started.players) {
            this.world.setupRemotePlayer(p);
          }
          for (let b of started.bonuses) {
            this.world.spawnBonus(b);
          }
          for (let c of this.started_callbacks) c(started);
          this.started = true;
          console.log("CONTAINER-ID: " + this.docker_id);
          console.log("SERVER-ID: " + this.server_id);
          console.log("ROOM-ID: " + this.room_id);
        } else {
          this.stateCode = StateCodes.SERVER_FULL;
        }
        break;

      case "updated":
        let updated = data.d as UpdatedResult;
        //console.log("UPDATED:", updated);

        // Add new remote players
        if (updated.joins.length > 0) {
          //console.log("NEW JOINS", updated.joins);
          for (let p of updated.joins) {
            let player = this.world.players.find((q) => {
              return q.blob.id === p.id;
            });
            if (player && this.world.players[0].blob.id === p.id) {
              continue;
            } else if (!player) {
              this.world.setupRemotePlayer(p);
            }
          }
        }

        // Update positions
        if (updated.players.length) {
          for (let p of updated.players) {
            let player = this.world.players.find((q) => {
              return q.blob.id === p.id;
            });
            if (player && this.world.players[0].blob.id === p.id) {
              continue;
            } else if (player && player.blob.isAlive) {
              player.blob.position = p.pos;
              player.sprite.x = p.pos[0];
              player.sprite.y = p.pos[1];
            }
          }
        }

        // Update bonus positions
        if (updated.bonuses_positions.length) {
          for (let p of updated.bonuses_positions) {
            let bonus = this.world.bonuses.find((q) => {
              return q.id === p.id;
            });
            if (bonus) {
              bonus.sprite.x = p.point[0];
              bonus.sprite.y = p.point[1];
            }
          }
        }

        if (updated.claims.length > 0) {
          //console.log("NEW CLAIMS", updated.claims);
          for (let claim of updated.claims) {
            let player = this.world.players.find((p) => {
              return p.blob.id === claim.id;
            });
            if (player.blob.isMain) {
              this.world.playClaimSound();
            }
            if (player && player.blob.isAlive) {
              player.blob.updateClaimedArea(claim.claims);
              player.blob.disposeTrail(claim.pos);
            }
          }
        }

        if (updated.steals.length > 0) {
          //console.log("NEW STEALS", updated.steals);
          for (let steal of updated.steals) {
            let player = this.world.players.find((p) => {
              return p.blob.id === steal.id;
            });
            if (player.blob.isMain) {
              this.world.playClaimSound();
            }
            if (player && player.blob.isAlive) {
              player.blob.updateClaimedArea(steal.claims);
            }
          }
        }

        if (updated.kills.length > 0) {
          //console.log("NEW KILLS", updated.kills);
          for (let kill of updated.kills) {
            this.world.removePlayer(kill);
          }
        }

        if (updated.scoreboard) {
          for (let score of updated.scoreboard) {
            let player = this.world.players.find((p) => {
              return p.blob.id === score.id;
            });
            if (player) {
              player.blob.score = score.score;
            }
          }
        }

        if (updated.wins.length > 0) {
          //console.log("NEW WINS", updated.wins);
          let player = this.world.players.find((p) => {
            return p.blob.id === updated.wins[0].id;
          });
          if (player) {
            this.world.createWin(player);
          }
        }

        if (updated.bonuses.length > 0) {
          //console.log("NEW BONUSES", updated.bonuses);
          for (let bonus of updated.bonuses) {
            this.world.updateBonus(bonus);
          }
        }

        for (let c of this.update_callbacks) c(updated);

        break;

      case "leaderboard":
        let lb = data.d as LeaderBoardResult;
        if (lb.monthly.length > 0) {
          this.leaderboards = lb;
          this.leaderboardsUpdated = true;
        }
        break;

      default:
        console.error("unsupported event", data);
    }
  }

  connect_one_shot(url: string) {
    //console.log('this (connect_one_shot)', this)
    wss = new WebSocket(url);
    wss.addEventListener("open", (event) => this.onopen.call(this, event));
    wss.addEventListener("message", (event) =>
      this.onreceive.call(this, event)
    );
    wss.addEventListener("error", this.onerror);
    wss.addEventListener("close", (event) => this.onclose.call(this, event));
  }

  connect_with_retry(url: string, err?: any | void) {
    // console.log('this (connect_with_retry)', this)
    let hub = this;
    hub.wss_retry_url = url;
    var back = attempt || (attempt = new Back());
    return back.backoff(function (fail) {
      if (attempt.settings.attempt > 1) {
        console.log(
          `Retry connection attempt #${attempt.settings.attempt} being made after ${attempt.settings.timeout} ms`
        );
      }
      if (fail) {
        console.error("Oh noez given up retrying to connect...");
        if (err) console.error(err.message);
      }
      hub.connect_one_shot(url);
    });
  }

  onopen(e) {
    //console.log('this (onopen)', this)
    this.state = "[open] Connection established";
    this.stateCode = StateCodes.CONNECTION_OPEN;
    attempt = null;
    console.log(this.state);
  }

  onclose(event) {
    console.log("this (onclose)", this);
    if (event.wasClean) {
      this.state = `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`;
      this.stateCode = StateCodes.CONNECTION_CLOSED_CLEAN;
    } else {
      // e.g. server process killed or network down - event.code is usually 1006 in this case
      this.state = "[close] Connection died";
      this.stateCode = StateCodes.CONNECTION_DIED;
    }
    console.log(this.state);
    //this.connect_with_retry(this.wss_retry_url);
  }

  onerror(error) {
    console.log("[error]", error);
    this.stateCode = StateCodes.CONNECTION_ERROR;
  }
}

export interface ConnectedResult {
  server_id: String; // Need to change to Guid
  docker_id: String;
  connections: number;
  gameoptions: {};
  leaderboards: LeaderBoardResult;
}

export interface StartedResult {
  ok: boolean;
  name_error: string;
  world?: WorldResult;
  you?: NewBlobResult;
  players?: [NewBlobResult];
  scoreboard?: [ScoresResult];
  room_id: string;
  bonuses: [Bonus];
}

export interface UpdatedResult {
  players: [UpdateBlobResult];
  joins: [NewBlobResult];
  claims: [Claim];
  steals: [Claim];
  kills: [Kill];
  scoreboard: [ScoresResult];
  wins: [Win];
  bonuses: [any];
  bonuses_positions: [any];
}

export interface LeaderBoardResult {
  monthly: [any];
  weekly: [any];
  daily: [any];
}

export interface ScoresResult {
  id: string;
  name: string;
  score: number;
}

export interface WorldResult {
  centre: number[];
  boundary: number[][];
  physics: {
    DEFAULT_SPEED: number;
    DEFAULT_ACCEL: number;
    DEFAULT_DRAG: number;
  };
}

export interface NewBlobResult {
  id: number;
  name: string;
  colour: number;
  colourx: string;
  colour3: any;
  image: string;
  pos: number[];
  score: number;
  trail: number[][];
  claim: number[][][];
  joined: {
    ready: boolean;
    dt: number;
  };
  killed: {
    is_killed: boolean;
    by: string;
    message: string;
  };
  is_ai: boolean;
  wireframe: boolean;
}

export interface UpdateBlobResult {
  id: number;
  name: string;
  pos: number[];
}

export interface Claim {
  id: number;
  name: string;
  claims: number[][][];
  pos: [];
}

export interface Kill {
  id: number;
  name: string;
  is_killed: boolean;
  killed_by_name: string;
  killed_message: string;
}

export interface Win {
  id: number;
  name: string;
  score: number;
}

export interface Bonus {
  id: any;
  type: string;
  amount: number;
  point: [];
}
