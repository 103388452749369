import { Blob } from "./Blob";
import { getRandomBezierPath, getStraightPath } from "./Utils";
import * as turf from "@turf/turf";
import { worldHeight, worldWidth } from "./start";

export class BlobAI extends Blob {
  numberOfMovements: number;
  AImoving: boolean;
  startPosition;
  isAI = true;
  AI_dt = 0;
  path2: any;
  //path2Length = 0;
  speed_dt = 0.015;
  worldWidth;
  worldHeight;

  constructor(icon, position, name, speed_dt) {
    super(icon, position, name);
    this.numberOfMovements = 0;
    this.startPosition = position;
    this.speed_dt = speed_dt;
    this.worldWidth = worldWidth;
    this.worldHeight = worldHeight;
  }

  getCentroidPoint() {
    if (this.claimedAreaTurf) {
      const area = this.claimedAreaTurf;
      const turfPoint = turf.centroid(area);
      return turfPoint.geometry.coordinates;
    } else {
      return this.startPosition;
    }
  }

  AIThink(sprite) {
    if (!this.path2) {
      this.path2 = getRandomBezierPath(
        [this.position[0], this.position[1]],
        this.worldWidth,
        this.worldHeight
      );
      //this.path2Length = this.path2.getLength();
    }

    if (this.numberOfMovements > 5) {
      // goHome
      const centroidPoint = this.getCentroidPoint();
      this.AI_dt = 0;
      this.path2 = getStraightPath(
        [this.position[0], this.position[1]],
        [centroidPoint[0], centroidPoint[1]]
      );
      //this.path2Length = this.path2.getLength();
      this.numberOfMovements = 0;
    }

    if (this.AI_dt > 1) {
      this.numberOfMovements++;
      this.AI_dt = 0;
      this.path2 = getRandomBezierPath(
        [this.position[0], this.position[1]],
        this.worldWidth,
        this.worldHeight
      );
      //this.path2Length = this.path2.getLength();
    }

    // get point based on length of arc and ai speed
    // var time = this.path2Length / this.speed_dt;
    // var relativeValue = (this.AI_dt / time) * time;
    // let p = this.path2.getPoint(relativeValue);

    let p = this.path2.getPoint(this.AI_dt);

    var ease = 10;

    if (p) {
      // go home if new point is out of bounds
      if (
        p.x < ease ||
        p.x > this.worldWidth - ease ||
        p.y < ease ||
        p.x > this.worldHeight - ease
      ) {
        const centroidPoint = this.getCentroidPoint();
        this.path2 = getStraightPath(
          [this.position[0], this.position[1]],
          [centroidPoint[0], centroidPoint[1]]
        );
        //this.path2Length = this.path2.getLength();
        this.numberOfMovements = 0;
        this.AI_dt = 0;
      } else {
        sprite.x = p.x;
        sprite.y = p.y;
      }
    }

    this.AI_dt += this.speed_dt;
  }
}
