import { Scene } from "phaser";
import { Hub, StateCodes } from "./Hub";
import { mainSceneHuman } from "./start";
import { abbreviateNumber } from "./Utils";

export class WelcomeScene extends Scene {
  k8s = false;
  welcome;
  mainPlayerName = "";
  difficulty = "easy";
  playersAI = "4";
  isOpen;
  buttons;
  sounds;
  hub;
  connected = false;
  nameInput;
  persistingData;
  injectTo;
  injected = false;
  controllerHost;
  host;
  startMainSceneHuman = false;
  startMainSceneHumanPrivate = false;
  leaderboards;
  leaderboardsUpdated = false;
  changelog;
  changelogUpdated = false;
  discordUrl: string;
  discordUrlUpdated = false;
  publicRooms: [];
  maxPlayers: number;

  constructor() {
    super("welcomeScene");
    // Server comms
    this.k8s = process.env.KUBERNETES === "true" ? true : false;
    this.host =
      process.env.NODE_ENV === "production"
        ? process.env.HOST2
        : "http://0.0.0.0:8080";

    this.controllerHost =
      process.env.NODE_ENV === "production"
        ? process.env.HOST
        : "http://0.0.0.0:5001";
  }

  init(persistingData) {
    this.connected = false;
    this.publicRooms = [];
    if (Object.values(persistingData).length > 0) {
      this.persistingData = persistingData;
    }

    fetch(this.controllerHost)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message == "Connected") {
          this.connected = true;
          console.log("Connected to Rest API");
        }
      })
      .catch((error) => console.log(error));

    fetch(this.controllerHost + "/leaderboards")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.leaderboards = data;
        this.leaderboardsUpdated = true;
        console.log("Retrieved leaderboards");
      })
      .catch((error) => console.log(error));

    fetch(this.controllerHost + "/discord/url")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.discordUrl = data.discord_url;
        this.discordUrlUpdated = true;
      })
      .catch((error) => console.log(error));

    fetch(this.controllerHost + "/changelog")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.changelog = data;
        this.changelogUpdated = true;
        console.log("Retrieved Changelog");
      })
      .catch((error) => console.log(error));

    fetch(this.controllerHost + "/public_rooms")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.publicRooms = data["rooms"];
        this.maxPlayers = data["max_players"];
        this.fillPublicRooms(true, true);
        console.log("Retrieved public rooms");
      })
      .catch((error) => console.log(error));

    console.log(`${process.env.NODE_ENV}`);
  }

  async create() {
    document.getElementById("injectToScores").style.display = "none";
    document.getElementById("injectToMenu").style.display = "none";
    document.getElementById("injectToEndPage").style.display = "none";

    this.injectTo = document.getElementById("injectToWelcome");
    this.injected = false;

    await fetch("html/Welcome.html")
      .then((response) => response.text())
      .then((text) => {
        this.injectTo.innerHTML = text;
        this.injectTo.style.display = "flex";
        this.injectTo.style.visibility = "visible";
        this.injectTo.style.transform = "scale(1)";
        this.injected = true;
      });

    if (this.persistingData) {
      (<HTMLInputElement>document.getElementById("nameInput")).value = this
        .persistingData.mainPlayerName
        ? this.persistingData.mainPlayerName
        : "";
      (<HTMLInputElement>document.getElementById("gameMusicCheckbox")).checked =
        this.persistingData.gameMusic;
      (<HTMLInputElement>(
        document.getElementById("gameSoundsCheckbox")
      )).checked = this.persistingData.gameSounds;

      window.localStorage.setItem("gameMusic", this.persistingData.gameMusic);
      window.localStorage.setItem("gameSounds", this.persistingData.gameSounds);
    } else {
      var name = window.localStorage.getItem("player_name");
      var gm = window.localStorage.getItem("gameMusic");
      var gs = window.localStorage.getItem("gameSounds");

      if (name) {
        (<HTMLInputElement>document.getElementById("nameInput")).value = name;
      }
      if (gm) {
        (<HTMLInputElement>(
          document.getElementById("gameMusicCheckbox")
        )).checked = gm === "true";
      }
      if (gs) {
        (<HTMLInputElement>(
          document.getElementById("gameSoundsCheckbox")
        )).checked = gs === "true";
      }
    }

    if (
      !(<HTMLInputElement>document.getElementById("gameMusicCheckbox"))
        .checked &&
      !(<HTMLInputElement>document.getElementById("gameSoundsCheckbox")).checked
    ) {
      var doc = document.getElementById("volumeImage") as HTMLImageElement;
      doc.src = "../images/volume-mute.svg";
    }

    this.sounds = {
      background: this.sound.add("background", { loop: true }),
      gameMusicChecked: (<HTMLInputElement>(
        document.getElementById("gameMusicCheckbox")
      )).checked,
      gameSoundsChecked: (<HTMLInputElement>(
        document.getElementById("gameSoundsCheckbox")
      )).checked,
    };

    if (this.sounds.gameMusicChecked === null || this.sounds.gameMusicChecked) {
      this.sounds.background.play();
    }

    this.isOpen = {
      controlsOpen: false,
      changelogOpen: false,
      settingsOpen: false,
      instructionsOpen: false,
      leaderboardOpen: false,
      privateRoomsOpen: false,
      publicRoomsOpen: false,
      joinRoomOpen: false,
      createRoomOpen: false,
    };

    this.buttons = {
      startAIButton: null,
      startHumanRandomButton: null,
      controlsButton: null,
      changelogButton: null,
      settingsButton: null,
      instructionsButton: null,
      closeButtonControls: null,
      closeButtonChangelog: null,
      closeButtonSettings: null,
      closeButtonInstructions: null,
      volumeButton: null,
      leaderboardButton: null,
      closeButtonLeaderboard: null,
      closeButtonPrivateRoom: null,
      closeButtonPublicRoom: null,
      createPrivateRoomButton: null,
      joinPublicRoomButton: null,
      joinPrivateRoomParentButton: null,
      joinPrivateRoomChildButton: null,
      playPrivateRoomButton: null,
      copyCodePrivateRoomButton: null,
      instantPlayButton: null,
      showFullRoomsButton: null,
      showEmptyRoomsButton: null,
    };

    this.addListeners();
  }

  update() {
    if (this.injected) {
      if (this.connected) {
        if (this.publicRooms.length > 0) {
          this.buttons.joinPublicRoomButton.disabled = false;
        }
        this.buttons.startHumanPrivateButton.disabled = false;
        this.buttons.instantPlayButton.disabled = false;
        document.querySelector("#serverMessagesState").innerHTML = "Connected!";
      } else if (!this.connected) {
        if (this.publicRooms.length == 0) {
          this.buttons.joinPublicRoomButton.disabled = true;
        }
        this.buttons.startHumanPrivateButton.disabled = true;
        this.buttons.instantPlayButton.disabled = true;
        document.querySelector("#serverMessagesState").innerHTML =
          "Trying to connect...";
      }
      if (
        this.hub &&
        this.startMainSceneHuman &&
        this.hub.stateCode === StateCodes.CONNECTION_OPEN
      ) {
        var data = {
          mainPlayerName: this.mainPlayerName,
          playersAI: this.playersAI,
          difficulty: this.difficulty,
          gameMusic: this.sounds.gameMusicChecked.checked,
          gameSounds: this.sounds.gameSoundsChecked.checked,
          hub: this.hub,
          host: this.host,
        };
        this.connected = false;
        this.startMainSceneHuman = false;
        this.scene.start("mainSceneHuman", data);
      } else if (
        this.hub &&
        this.startMainSceneHumanPrivate &&
        this.hub.stateCode === StateCodes.CONNECTION_OPEN
      ) {
        var data = {
          mainPlayerName: this.mainPlayerName,
          playersAI: this.playersAI,
          difficulty: this.difficulty,
          gameMusic: this.sounds.gameMusicChecked.checked,
          gameSounds: this.sounds.gameSoundsChecked.checked,
          hub: this.hub,
          host: this.host,
        };

        this.injectTo.style.display = "none";
        this.removeEventListeners();
        this.sounds.background.stop();

        this.connected = false;
        this.startMainSceneHumanPrivate = false;
        this.scene.start("mainSceneHuman", data);
      }

      if (this.hub && this.hub.state && !this.connected) {
        document.querySelector("#serverMessagesState").innerHTML =
          this.hub.state;
      }
      if (
        this.leaderboards &&
        this.leaderboards.monthly.length > 0 &&
        this.leaderboardsUpdated
      ) {
        var monthly = "";
        var weekly = "";
        var daily = "";

        this.leaderboards.monthly.forEach((p) => {
          monthly += `<div class="leaderboardText">${
            p.name
          }:&nbsp;&nbsp;&nbsp;&nbsp;${abbreviateNumber(p.score)}</div>`;
        });

        this.leaderboards.weekly.forEach((p) => {
          weekly += `<div class="leaderboardText">${
            p.name
          }:&nbsp;&nbsp;&nbsp;&nbsp;${abbreviateNumber(p.score)}</div>`;
        });

        this.leaderboards.daily.forEach((p) => {
          daily += `<div class="leaderboardText">${
            p.name
          }:&nbsp;&nbsp;&nbsp;&nbsp;${abbreviateNumber(p.score)}</div>`;
        });

        document.querySelector("#monthlyLeaderboard").innerHTML = monthly;
        document.querySelector("#weeklyLeaderboard").innerHTML = weekly;
        document.querySelector("#dailyLeaderboard").innerHTML = daily;

        this.leaderboardsUpdated = false;
      }

      if (this.changelog && this.changelogUpdated) {
        var text = "";
        this.changelog["changelog"].forEach((change) => {
          if (change["description"]) {
            text += `<li id="changelogRow">${change["description"]}</li>`;
          }
        });
        this.changelogUpdated = false;

        var doc = document.getElementById("changelogRows");
        doc.innerHTML = text;
      }

      if (this.discordUrl && this.discordUrlUpdated) {
        this.discordUrlUpdated = false;

        var doc = document.getElementById("discordLink");
        doc.setAttribute("href", this.discordUrl);
        doc.style.visibility = "visible";
      }
    }
  }

  addListeners() {
    this.buttons.volumeButton = document.getElementById("volumeButton");
    this.buttons.volumeButton.addEventListener("click", () =>
      this.onClickVolume()
    );

    this.buttons.leaderboardButton =
      document.getElementById("leaderboardButton");
    this.buttons.leaderboardButton.addEventListener("click", () =>
      this.onClickLeaderboard()
    );

    this.buttons.closeLeaderboardButton = document.getElementById(
      "closeButtonLeaderboard"
    );
    this.buttons.closeLeaderboardButton.addEventListener("click", () =>
      this.onClickLeaderboard()
    );

    this.buttons.startAIButton = document.getElementById("startAIButton");
    this.buttons.startAIButton.addEventListener("click", () =>
      this.onSubmit(true)
    );

    this.buttons.startHumanRandomButton = document.getElementById(
      "publicRoomsRandomButton"
    );
    this.buttons.startHumanRandomButton.addEventListener("click", () =>
      this.onSubmit(false)
    );

    this.buttons.instantPlayButton =
      document.getElementById("instantPlayButton");
    this.buttons.instantPlayButton.addEventListener("click", () =>
      this.onSubmit(false)
    );

    this.buttons.startHumanPrivateButton = document.getElementById(
      "startHumanPrivateButton"
    );
    this.buttons.startHumanPrivateButton.addEventListener("click", () =>
      this.onClickPrivateRooms()
    );

    this.buttons.controlsButton = document.getElementById("controlsToggle");
    this.buttons.controlsButton.addEventListener("click", () =>
      this.onClickControl()
    );

    this.buttons.changelogButton = document.getElementById("changelogToggle");
    this.buttons.changelogButton.addEventListener("click", () =>
      this.onClickChangelog()
    );

    this.buttons.settingsButton = document.getElementById("settingsToggle");
    this.buttons.settingsButton.addEventListener("click", () =>
      this.onClickSettings()
    );

    this.buttons.instructionsButton =
      document.getElementById("instructionsToggle");
    this.buttons.instructionsButton.addEventListener("click", () =>
      this.onClickInstructions()
    );

    this.buttons.closeButtonControls = document.getElementById(
      "closeButtonControls"
    );
    this.buttons.closeButtonControls.addEventListener("click", () =>
      this.onClickControl()
    );

    this.buttons.closeButtonChangelog = document.getElementById(
      "closeButtonChangelog"
    );
    this.buttons.closeButtonChangelog.addEventListener("click", () =>
      this.onClickChangelog()
    );

    this.buttons.closeButtonSettings = document.getElementById(
      "closeButtonSettings"
    );
    this.buttons.closeButtonSettings.addEventListener("click", () =>
      this.onClickSettings()
    );

    this.buttons.closeButtonInstructions = document.getElementById(
      "closeButtonInstructions"
    );
    this.buttons.closeButtonInstructions.addEventListener("click", () =>
      this.onClickInstructions()
    );

    this.buttons.closeButtonPrivateRooms = document.getElementById(
      "closeButtonPrivateRooms"
    );
    this.buttons.closeButtonPrivateRooms.addEventListener("click", () =>
      this.onClickPrivateRooms()
    );

    this.buttons.createPrivateRoomButton = document.getElementById(
      "privateRoomsCreateButton"
    );
    this.buttons.createPrivateRoomButton.addEventListener("click", () =>
      this.onClickCreateRoom()
    );

    this.buttons.joinPrivateRoomParentButton = document.getElementById(
      "privateRoomsJoinButton"
    );
    this.buttons.joinPrivateRoomParentButton.addEventListener("click", () =>
      this.onClickJoinRoom()
    );

    this.buttons.createBackPrivateRoomButton = document.getElementById(
      "privateRoomsCreateBackButton"
    );
    this.buttons.createBackPrivateRoomButton.addEventListener("click", () =>
      this.onClickCreateRoom()
    );

    this.buttons.joinBackPrivateRoomButton = document.getElementById(
      "privateRoomsJoinBackButton"
    );
    this.buttons.joinBackPrivateRoomButton.addEventListener("click", () =>
      this.onClickJoinRoom()
    );

    this.buttons.playPrivateRoomButton = document.getElementById(
      "privateRoomsPlayButton"
    );
    this.buttons.playPrivateRoomButton.addEventListener("click", () =>
      this.onPlayPrivateRoom()
    );

    this.buttons.joinPrivateRoomChildButton = document.getElementById(
      "privateRoomsJoinChildButton"
    );
    this.buttons.joinPrivateRoomChildButton.addEventListener("click", () =>
      this.onJoinPrivateRoom()
    );
    this.buttons.copyCodePrivateRoomButton = document.getElementById(
      "privateRoomsCopyCodeButton"
    );
    this.buttons.copyCodePrivateRoomButton.addEventListener("click", () =>
      this.onCopyPrivateRoom()
    );

    this.buttons.joinPublicRoomButton =
      document.getElementById("startHumanButton");
    this.buttons.joinPublicRoomButton.addEventListener("click", () =>
      this.onClickPublicRooms()
    );

    this.buttons.closeButtonPublicRooms = document.getElementById(
      "closeButtonPublicRooms"
    );
    this.buttons.closeButtonPublicRooms.addEventListener("click", () =>
      this.onClickPublicRooms()
    );

    this.sounds.gameMusicChecked = document.getElementById("gameMusicCheckbox");
    this.sounds.gameMusicChecked.addEventListener("change", () =>
      this.onChangeGameMusic()
    );

    this.sounds.gameSoundsChecked =
      document.getElementById("gameSoundsCheckbox");

    this.nameInput = <HTMLInputElement>document.getElementById("nameInput");
    this.nameInput.addEventListener("keypress", (e) =>
      this.nameInputListener(e)
    );

    this.buttons.showFullRoomsButton = document.getElementById("showFullRooms");
    this.buttons.showFullRoomsButton.addEventListener("input", () =>
      this.onCheckShowWhichRooms()
    );

    this.buttons.showEmptyRoomsButton =
      document.getElementById("showEmptyRooms");
    this.buttons.showEmptyRoomsButton.addEventListener("input", () =>
      this.onCheckShowWhichRooms()
    );
  }

  removeEventListeners() {
    this.buttons.startAIButton.removeEventListener("click", () =>
      this.onSubmit(true)
    );
    this.buttons.startHumanRandomButton.removeEventListener("click", () =>
      this.onSubmit(false)
    );
    this.buttons.instantPlayButton.removeEventListener("click", () =>
      this.onSubmit(false)
    );
    this.buttons.startHumanPrivateButton.removeEventListener("click", () =>
      this.onClickPrivateRooms()
    );
    this.buttons.controlsButton.removeEventListener("click", () =>
      this.onClickControl()
    );
    this.buttons.changelogButton.removeEventListener("click", () =>
      this.onClickChangelog()
    );
    this.buttons.settingsButton.removeEventListener("click", () =>
      this.onClickSettings()
    );
    this.buttons.instructionsButton.removeEventListener("click", () =>
      this.onClickInstructions()
    );
    this.buttons.closeButtonControls.removeEventListener("click", () =>
      this.onClickControl()
    );
    this.buttons.closeButtonChangelog.removeEventListener("click", () =>
      this.onClickChangelog()
    );
    this.buttons.closeButtonSettings.removeEventListener("click", () =>
      this.onClickSettings()
    );
    this.buttons.closeButtonInstructions.removeEventListener("click", () =>
      this.onClickInstructions()
    );
    this.buttons.volumeButton.removeEventListener("click", () =>
      this.onClickVolume()
    );
    this.buttons.leaderboardButton.removeEventListener("click", () =>
      this.onClickLeaderboard()
    );
    this.buttons.closeLeaderboardButton.removeEventListener("click", () =>
      this.onClickLeaderboard()
    );
    this.buttons.createPrivateRoomButton.removeEventListener("click", () =>
      this.onClickCreateRoom()
    );
    this.buttons.joinPrivateRoomParentButton.removeEventListener("click", () =>
      this.onClickJoinRoom()
    );
    this.buttons.createBackPrivateRoomButton.removeEventListener("click", () =>
      this.onClickCreateRoom()
    );
    this.buttons.joinBackPrivateRoomButton.removeEventListener("click", () =>
      this.onClickJoinRoom()
    );
    this.buttons.playPrivateRoomButton.removeEventListener("click", () =>
      this.onPlayPrivateRoom()
    );
    this.buttons.joinPrivateRoomChildButton.removeEventListener("click", () =>
      this.onJoinPrivateRoom()
    );
    this.buttons.copyCodePrivateRoomButton.removeEventListener("click", () =>
      this.onCopyPrivateRoom()
    );
    this.sounds.gameMusicChecked.removeEventListener("change", () =>
      this.onChangeGameMusic()
    );
    this.nameInput.removeEventListener("keypress", (e) =>
      this.nameInputListener(e)
    );
    this.buttons.showFullRoomsButton.removeEventListener("input", () =>
      this.onCheckShowWhichRooms()
    );
    this.buttons.showEmptyRoomsButton.removeEventListener("input", () =>
      this.onCheckShowWhichRooms()
    );
  }

  async onSubmit(isAI: boolean) {
    this.mainPlayerName = (<HTMLInputElement>(
      document.getElementById("nameInput")
    )).value;
    window.localStorage.setItem("player_name", this.mainPlayerName);
    this.playersAI = (<HTMLSelectElement>(
      document.getElementById("playersAIChecklist")
    )).value;
    this.difficulty = (<HTMLSelectElement>(
      document.getElementById("difficultyChecklist")
    )).value;

    this.injectTo.style.visibility = "hidden";
    this.injectTo.style.transform = "scale(0)";
    this.removeEventListeners();
    this.sounds.background.stop();

    await fetch(this.controllerHost + "/server/random")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // build url
        if (data) {
          if (process.env.NODE_ENV === "development") {
            this.host = `ws://0.0.0.0:8080/snake`;
          } else if (this.k8s) {
            this.host = `ws://0.0.0.0:${data.port}/snake`;
          } else {
            this.host = `${data.url.replace("https", "wss")}/snake`;
          }
          console.log("HOST: " + this.host);
          this.hub = new Hub(mainSceneHuman, []);
          this.hub.connect_with_retry(this.host);
        }
      })
      .catch((error) => console.log(error));

    var data = {
      mainPlayerName: this.mainPlayerName,
      playersAI: this.playersAI,
      difficulty: this.difficulty,
      gameMusic: this.sounds.gameMusicChecked.checked,
      gameSounds: this.sounds.gameSoundsChecked.checked,
      hub: this.hub,
    };

    // Stop current and launch mainscene
    if (isAI) {
      this.scene.start("mainSceneAI", data);
    } else {
      document.getElementById("loadingAnimation").style.display = "flex";
      this.startMainSceneHuman = true;
    }
  }

  async onCopyPrivateRoom() {
    var codeDoc = document.getElementById("privateRoomCode");
    navigator.clipboard.writeText(codeDoc.innerHTML);
    var copyIconDoc = document.getElementById(
      "privateRoomsCopyIcon"
    ) as HTMLImageElement;
    copyIconDoc.src = "../images/tick.png";
  }

  async onPlayPrivateRoom() {
    this.mainPlayerName = (<HTMLInputElement>(
      document.getElementById("nameInput")
    )).value;
    window.localStorage.setItem("player_name", this.mainPlayerName);
    this.playersAI = (<HTMLSelectElement>(
      document.getElementById("playersAIChecklist")
    )).value;
    this.difficulty = (<HTMLSelectElement>(
      document.getElementById("difficultyChecklist")
    )).value;
    document.getElementById("loadingAnimation").style.display = "flex";
    this.startMainSceneHumanPrivate = true;
  }

  async onJoinPrivateRoom() {
    var room = (<HTMLInputElement>document.getElementById("codeInput")).value;

    if (room) {
      await fetch(this.controllerHost + `/server/${room}`)
        .then((response) => {
          if (!response.ok) {
            alert("Room not found");
          }
          return response.json();
          // TO DO - Error for 404
        })
        .then((data) => {
          // build url
          if (data) {
            if (process.env.NODE_ENV === "development") {
              this.host = `ws://0.0.0.0:8080/snake/${room}`;
            } else if (this.k8s) {
              this.host = `ws://0.0.0.0:${data.port}/snake/${room}`;
            } else {
              this.host = `${data.url.replace("https", "wss")}/snake/${room}`;
            }

            console.log("HOST: " + this.host);
            this.hub = new Hub(mainSceneHuman, []);
            this.hub.connect_with_retry(this.host);
          }
        })
        .catch(() => console.log("Room not found"));

      this.mainPlayerName = (<HTMLInputElement>(
        document.getElementById("nameInput")
      )).value;
      window.localStorage.setItem("player_name", this.mainPlayerName);
      this.playersAI = (<HTMLSelectElement>(
        document.getElementById("playersAIChecklist")
      )).value;
      this.difficulty = (<HTMLSelectElement>(
        document.getElementById("difficultyChecklist")
      )).value;
      document.getElementById("loadingAnimation").style.display = "flex";
      this.startMainSceneHumanPrivate = true;
    } else {
      alert("You need to enter a room code.");
    }
  }

  onChangeGameMusic() {
    if (this.sounds.gameMusicChecked.checked) {
      this.sounds.background.play();
    } else {
      this.sounds.background.stop();
    }
  }

  onClickControl() {
    var doc = document.getElementById("controlsLightBox");
    if (this.isOpen.controlsOpen) {
      doc.style.visibility = "hidden";
      doc.style.transform = "scale(0)";
      this.isOpen.controlsOpen = false;
    } else if (!this.isOpen.controlsOpen) {
      doc.style.visibility = "visible";
      doc.style.transform = "scale(1)";
      this.isOpen.controlsOpen = true;
    }
  }

  onClickChangelog() {
    var doc = document.getElementById("changelogLightBox");
    if (this.isOpen.changelogOpen) {
      doc.style.visibility = "hidden";
      doc.style.transform = "scale(0)";
      this.isOpen.changelogOpen = false;
    } else if (!this.isOpen.changelogOpen) {
      doc.style.visibility = "visible";
      doc.style.transform = "scale(1)";
      this.isOpen.changelogOpen = true;
    }
  }

  onClickSettings() {
    var doc = document.getElementById("settingsLightBox");
    if (this.isOpen.settingsOpen) {
      doc.style.visibility = "hidden";
      doc.style.transform = "scale(0)";
      this.isOpen.settingsOpen = false;
    } else if (!this.isOpen.settingsOpen) {
      doc.style.visibility = "visible";
      doc.style.transform = "scale(1)";
      this.isOpen.settingsOpen = true;
    }
  }

  onClickInstructions() {
    var doc = document.getElementById("instructionsLightBox");
    if (this.isOpen.instructionsOpen) {
      doc.style.visibility = "hidden";
      doc.style.transform = "scale(0)";
      this.isOpen.instructionsOpen = false;
    } else if (!this.isOpen.instructionsOpen) {
      doc.style.visibility = "visible";
      doc.style.transform = "scale(1)";
      this.isOpen.instructionsOpen = true;
    }
  }

  onClickVolume() {
    var doc = document.getElementById("volumeImage") as HTMLImageElement;
    if (doc.src.includes("/volume-on.svg")) {
      doc.src = "../images/volume-mute.svg";
      this.sounds.gameMusicChecked.checked = false;
      this.sounds.gameSoundsChecked.checked = false;
      this.sounds.background.stop();
      window.localStorage.setItem("gameMusic", "false");
      window.localStorage.setItem("gameSounds", "false");
    } else if (doc.src.includes("/volume-mute.svg")) {
      this.sounds.gameMusicChecked.checked = true;
      this.sounds.gameSoundsChecked.checked = true;
      this.sounds.background.play();
      doc.src = "../images/volume-on.svg";
      window.localStorage.setItem("gameMusic", "true");
      window.localStorage.setItem("gameSounds", "true");
    }
  }

  onClickLeaderboard() {
    var doc = document.getElementById("leaderboardLightBox");
    if (this.isOpen.leaderboardOpen) {
      doc.style.visibility = "hidden";
      doc.style.transform = "scale(0)";
      this.isOpen.leaderboardOpen = false;
    } else if (!this.isOpen.leaderboardOpen) {
      doc.style.visibility = "visible";
      doc.style.transform = "scale(1)";
      this.isOpen.leaderboardOpen = true;
    }
  }

  onClickPrivateRooms() {
    var doc = document.getElementById("privateRoomsLightBox");
    if (this.isOpen.privateRoomsOpen) {
      doc.style.visibility = "hidden";
      doc.style.transform = "scale(0)";
      this.isOpen.privateRoomsOpen = false;
      var defDoc = document.getElementById("privateRoomsDefault");
      var createDoc = document.getElementById("privateRoomsCreate");
      var joinDoc = document.getElementById("privateRoomsJoin");
      defDoc.style.display = "flex";
      createDoc.style.display = "none";
      joinDoc.style.display = "none";
      if (this.hub) {
        this.hub.close_websocket();
      }
    } else if (!this.isOpen.privateRoomsOpen) {
      doc.style.visibility = "visible";
      doc.style.transform = "scale(1)";
      this.isOpen.privateRoomsOpen = true;
    }
  }

  onClickPublicRooms() {
    var doc = document.getElementById("publicRoomsLightBox");
    this.buttons.showFullRoomsButton.checked = true;
    if (this.isOpen.publicRoomsOpen) {
      doc.style.visibility = "hidden";
      doc.style.transform = "scale(0)";
      this.isOpen.publicRoomsOpen = false;
    } else if (!this.isOpen.publicRoomsOpen) {
      fetch(this.controllerHost + "/public_rooms")
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          this.publicRooms = data["rooms"];
          this.maxPlayers = data["max_players"];
          this.fillPublicRooms(true, true);
          doc.style.visibility = "visible";
          doc.style.transform = "scale(1)";
          this.isOpen.publicRoomsOpen = true;
          console.log("Retrieved public rooms");
        })
        .catch((error) => console.log(error));
    }
  }

  onClickJoinRoom() {
    var defDoc = document.getElementById("privateRoomsDefault");
    var doc = document.getElementById("privateRoomsJoin");
    if (this.isOpen.createRoomOpen) {
      doc.style.display = "none";
      this.isOpen.createRoomOpen = false;
      defDoc.style.display = "flex";
    } else if (!this.isOpen.createRoomOpen) {
      doc.style.display = "flex";
      this.isOpen.createRoomOpen = true;
      defDoc.style.display = "none";
    }
  }

  onClickCreateRoom() {
    var defDoc = document.getElementById("privateRoomsDefault");
    var doc = document.getElementById("privateRoomsCreate");
    if (this.isOpen.joinRoomOpen) {
      doc.style.display = "none";
      this.isOpen.joinRoomOpen = false;
      defDoc.style.display = "flex";

      if (this.hub) {
        this.hub.close_websocket();
      }
    } else if (!this.isOpen.joinRoomOpen) {
      doc.style.display = "flex";
      this.isOpen.joinRoomOpen = true;
      defDoc.style.display = "none";

      var codeDoc = document.getElementById("privateRoomCode");
      codeDoc.innerHTML = "Generating code ...";

      var playDoc = document.getElementById(
        "privateRoomsPlayButton"
      ) as HTMLButtonElement;
      playDoc.disabled = true;

      var copyDoc = document.getElementById(
        "privateRoomsCopyCodeButton"
      ) as HTMLButtonElement;
      copyDoc.disabled = true;
      // copyDoc.innerHTML = "Copy";
      var copyIconDoc = document.getElementById(
        "privateRoomsCopyIcon"
      ) as HTMLImageElement;
      copyIconDoc.src = "../images/copy.svg";

      var backDoc = document.getElementById(
        "privateRoomsCreateBackButton"
      ) as HTMLButtonElement;
      backDoc.disabled = true;

      fetch(this.controllerHost + `/server/generate_room`)
        .then((response) => {
          if (!response.ok) {
            //throw new Error(`Request failed with status ${response.status}`);
            alert("No private rooms available.");
          }
          return response.json();
        })
        .then((data) => {
          if (data && data.url) {
            if (process.env.NODE_ENV === "development") {
              this.host = `ws://0.0.0.0:8080/snake/${data.room_id}`;
            } else if (this.k8s) {
              this.host = `ws://0.0.0.0:${data.port}/snake/${data.room_id}`;
            } else {
              this.host = `${data.url.replace("https", "wss")}/snake/${
                data.room_id
              }`;
            }
            console.log("HOST: " + this.host);
            this.hub = new Hub(mainSceneHuman, []);
            this.hub.connect_with_retry(this.host);
            codeDoc.innerHTML = data.room_id;
            playDoc.disabled = false;
            copyDoc.disabled = false;
            backDoc.disabled = false;
          } else {
            alert("No private rooms available.");
            return;
          }
        })
        .catch((error) => console.log(error));
    }
  }

  fillPublicRooms(full: boolean, empty: boolean) {
    var doc = document.getElementById("publicRoomsList");
    var text = "";
    var className = "publicRoomsJoinButtons";
    var index = 0;
    var filteredRooms = this.publicRooms
      .filter((room) => {
        return full ? true : room["no_of_players"] !== this.maxPlayers;
      })
      .filter((room) => {
        return empty ? true : room["no_of_players"] !== 0;
      });
    filteredRooms.forEach((room) => {
      text += `
        <li class="publicRoomsListItem">
          <div class="publicRoomsListItemText">${room["id"]} - <b>${room["no_of_players"]}/${this.maxPlayers} players</b></div>
          <button class="publicRoomsListItemJoinButton" id="${className}-${index}" disabled>Join</button>
        </li>
      `;
      index++;
    });

    doc.innerHTML = text;

    for (var i = 0; i < filteredRooms.length; i++) {
      var joinButton = document.getElementById(`${className}-${i}`);
      joinButton.addEventListener(
        "click",
        this.onClickJoinPublicRooms.bind(this, i)
      );
      (joinButton as HTMLButtonElement).disabled = false;
    }
  }

  async onClickJoinPublicRooms(i: number) {
    var roomButtons = document.getElementsByClassName(
      "publicRoomsListItemJoinButton"
    );
    for (let r of roomButtons) {
      (r as HTMLButtonElement).disabled = true;
    }

    await fetch(this.controllerHost + `/server/${this.publicRooms[i]["id"]}`)
      .then((response) => {
        if (!response.ok) {
          //throw new Error(`Request failed with status ${response.status}`);
          alert("Room not available.");
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.url) {
          if (process.env.NODE_ENV === "development") {
            this.host = `ws://0.0.0.0:8080/snake/${this.publicRooms[i]["id"]}`;
          } else if (this.k8s) {
            this.host = `ws://0.0.0.0:${data.port}/snake/${this.publicRooms[i]["id"]}`;
          } else {
            this.host = `${data.url.replace("https", "wss")}/snake/${
              this.publicRooms[i]["id"]
            }`;
          }
          console.log("HOST: " + this.host);
          this.hub = new Hub(mainSceneHuman, []);
          this.hub.connect_with_retry(this.host);

          this.onClickJoinRoom();
          this.mainPlayerName = (<HTMLInputElement>(
            document.getElementById("nameInput")
          )).value;
          window.localStorage.setItem("player_name", this.mainPlayerName);
          this.playersAI = (<HTMLSelectElement>(
            document.getElementById("playersAIChecklist")
          )).value;
          this.difficulty = (<HTMLSelectElement>(
            document.getElementById("difficultyChecklist")
          )).value;
          document.getElementById("loadingAnimation").style.display = "flex";
          this.startMainSceneHuman = true;
        } else {
          alert("Room not available.");
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        var roomButtons = document.getElementsByClassName(
          "publicRoomsListItemJoinButton"
        );
        for (let r of roomButtons) {
          (r as HTMLButtonElement).disabled = true;
        }
      });
  }

  onCheckShowWhichRooms() {
    var isCheckedFull = this.buttons.showFullRoomsButton.checked;
    var isCheckedEmpty = this.buttons.showEmptyRoomsButton.checked;
    this.fillPublicRooms(
      isCheckedFull ? true : false,
      isCheckedEmpty ? true : false
    );
  }

  nameInputListener(e) {
    if (e.which === 32) {
      e.preventDefault();
    } else if (e.which === 13 && this.connected) {
      this.onSubmit(false);
    }
  }
}
