export const GAME_THEME = {
  default: {
    bonuses: {
      points: [{ name: "star", size: 30 }],
      spook: [{ name: "ghost", size: 35 }],
    },
  },
  hallowean: {
    bonuses: {
      points: [
        { name: "ghost", size: 45 },
        { name: "ghost2", size: 57 },
        { name: "pumpkin", size: 50 },
      ],
      spook: [{ name: "ghost", size: 35 }],
    },
  },
  christmas: {
    bonuses: {
      points: [
        { name: "star", size: 30 },
        { name: "christmasTree", size: 40 },
      ],
      spook: [{ name: "snowflake", size: 35 }],
    },
  },
};
