import { Scene } from "phaser";

export class PreloadScene extends Scene {
  constructor() {
    super("preloadScene");
  }

  preload() {
    var progressBar = this.add.graphics();
    var progressBox = this.add.graphics();

    var originX = this.game.canvas.width / 2;
    var originY = this.game.canvas.height / 2;

    progressBar.depth = 7;
    progressBox.depth = 6;
    progressBox.fillStyle(0x132440, 1);
    progressBox.fillRoundedRect(originX - 163, originY - 29, 320, 50, 8);

    var width = this.cameras.main.width;
    var height = this.cameras.main.height;

    var loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 60,
      text: "Loading...".toUpperCase(),
      style: {
        font: "23px Teko",
        color: "#ffffff",
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    var percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: "0%",
      style: {
        font: "21px Teko",
        color: "#ffffff",
      },
    });
    percentText.setOrigin(0.5, 0.5);
    percentText.depth = 8;

    var assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "".toUpperCase(),
      style: {
        font: "23px Teko",
        color: "#ffffff",
      },
    });
    assetText.setOrigin(0.5, 0.5);

    this.load.on("progress", function (value) {
      percentText.setText((value * 100).toFixed(2) + "%");
      progressBar.clear();
      progressBar.fillStyle(0x184b13, 1);
      progressBar.fillRoundedRect(
        originX - 153,
        originY - 19,
        300 * value,
        30,
        4
      );
    });

    this.load.on("fileprogress", function (file) {
      assetText.setText(("Loading Asset: " + file.key).toUpperCase());
    });
    this.load.on("complete", function () {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
    });

    this.load.audio("background", ["/audio/background.mp3"]);

    // Sprite images
    this.load.image("apple", "/images/apple.svg");
    this.load.image("cherry", "/images/cherry.svg");
    this.load.image("banana", "/images/banana.svg");
    this.load.image("kiwi", "/images/kiwi.svg");
    this.load.image("orange", "/images/orange.svg");
    this.load.image("grapes", "/images/grapes.svg");
    this.load.image("pear", "/images/pear.svg");
    this.load.image("mango", "/images/mango.svg");
    this.load.image("cabbage", "/images/cabbage.png");
    this.load.image("lemon", "/images/lemon.svg");
    this.load.image("rambutan", "/images/rambutan.svg");
    this.load.image("strawberry", "/images/strawberry.svg");
    this.load.image("watermelon", "/images/watermelon.svg");

    // bonuses and themes
    this.load.image("star", "/images/star.svg");
    this.load.image("ghost", "/images/themes/october/ghost.png");
    this.load.image("ghost2", "/images/themes/october/ghost2.svg");
    this.load.image("pumpkin", "/images/themes/october/pumpkin.png");
    this.load.image(
      "christmasTree",
      "/images/themes/december/christmasTree2.png"
    );
    this.load.image("snowflake", "/images/themes/december/snowflake.png");

    //audio
    this.load.audio("kill", ["/audio/kill.mp3"]);
    this.load.audio("claim", ["/audio/claim.mp3"]);
    this.load.audio("win", ["/audio/win.mp3"]);
    this.load.audio("background", ["/audio/background.mp3"]);
    this.load.audio("lose", ["/audio/lose.mp3"]);
    this.load.audio("bonus", ["/audio/bonus.mp3"]);
  }

  create() {
    this.scene.start("welcomeScene");
    this.scene.stop();
  }
}
