import { Scene } from "phaser";
import { Blob } from "./Blob";
import { BlobAI } from "./BlobAI";
import { gameWidth, worldHeight, worldWidth } from "./start";
import { distanceBetweenTwoPoints } from "./Utils";

export class MainSceneAI extends Scene {
  keyMap = {};
  players = [];
  scoresheet;
  totalArea;
  mainPlayerName;
  gameWidth;
  gameHeight;
  worldWidth;
  worldHeight;
  trailWidth;
  mainPlayerSpeed;
  speedOffset = 50;
  aiSpeedDt;
  numberOfAIplayers;
  gameEnded = false;
  sounds;
  dataFromWelcome;
  sceneChanging = false;
  controlZoneMaxDistance = 190;
  controlZoneMaxTime = 50;
  controlZoneDt = 1;

  constructor() {
    super("mainSceneAI");
    this.worldWidth = worldWidth;
    this.worldHeight = worldHeight;
    this.trailWidth = 13;
    this.mainPlayerSpeed = 500;
    this.aiSpeedDt = 0.005; // default if not specified
  }

  init(data) {
    this.dataFromWelcome = data;

    if (data.difficulty === "slow") {
      this.aiSpeedDt = 0.0125;
      this.mainPlayerSpeed = 300;
    } else if (data.difficulty === "normal") {
      this.aiSpeedDt = 0.016;
      this.mainPlayerSpeed = 400;
    } else if (data.difficulty === "fast") {
      this.aiSpeedDt = 0.018;
      this.mainPlayerSpeed = 500;
    }

    this.numberOfAIplayers = data.playersAI;

    var zoom =
      Math.max(this.game.canvas.height, this.game.canvas.width) / gameWidth;
    this.cameras.main.setZoom(zoom);
  }

  create() {
    //  Set the camera and physics bounds to be the size of 4x4 bg images
    this.cameras.main.setBounds(0, 0, this.worldWidth, this.worldHeight);
    this.physics.world.setBounds(0, 0, this.worldWidth, this.worldHeight);

    this.gameWidth = this.game.canvas.width;
    this.gameHeight = this.game.canvas.height;

    this.totalArea = this.worldWidth * this.worldHeight;
    this.gameEnded = false;
    this.sceneChanging = false;
    this.loadMainPlayer();
    this.loadAI();

    this.sounds = {
      kill: this.sound.add("kill"),
      background: this.sound.add("background", { loop: true, volume: 1 }),
      claim: this.sound.add("claim"),
    };

    if (this.dataFromWelcome.gameMusic) {
      this.sounds.background.play();
    }

    // launch scorescene and menuscene in parallel
    this.scene.launch("scoresSceneAI", this);
    this.scene.launch("mainMenuScene", { ...this, aiScene: true });
  }

  //  ** Main method running every frame **
  update() {
    if (this.players[0].blob.isAlive) {
      this.checkMovement();
      this.updateMainPlayerGraphics();

      if (
        Math.abs(this.players[0].sprite.x - this.players[0].circle.x) > 5 ||
        Math.abs(this.players[0].sprite.y - this.players[0].circle.y) > 5
      ) {
        this.players[0].circle.x = this.players[0].sprite.x;
        this.players[0].circle.y = this.players[0].sprite.y;
      }
    }

    //this.checkZoom();
    this.AIThink();

    this.killTest();

    this.players.forEach((player) => {
      player.blob.updateScore();
    });

    if (!this.gameEnded) {
      this.checkPlayers();
    }
  }

  loadMainPlayer() {
    this.players.push({
      blob: new Blob(
        "apple",
        [this.worldWidth / 2, this.worldHeight / 2],
        this.dataFromWelcome.mainPlayerName
          ? this.dataFromWelcome.mainPlayerName
          : "You"
      ),
      sprite: this.physics.add.sprite(
        this.worldWidth / 2,
        this.worldHeight / 2,
        "apple"
      ),
      trailGraphic: this.add.graphics(),
      claimedAreaGraphic: this.add.graphics(),
      trailColour: 0x99cbcb,
      claimedAreaColour: 0x99cbcb,
      keyboardUsed: true,
      circle: this.add.circle(this.gameWidth, this.gameHeight, 45, null, 0.2),
    });

    this.players[0].sprite.displayWidth = 50;
    this.players[0].sprite.scaleY = this.players[0].sprite.scaleX;
    this.players[0].sprite.setCollideWorldBounds(true);
    this.players[0].sprite.depth = 15;
    this.players[0].trailGraphic.depth = 14;
    this.players[0].claimedAreaGraphic.depth = 13;
    this.cameras.main.startFollow(this.players[0].sprite, true);

    this.players[0].circle.depth = 16;
    this.physics.add.existing(this.players[0].circle);
    this.players[0].circle.body.setCollideWorldBounds(true);

    window.onkeydown = window.onkeyup = (e) => {
      this.keyMap[e.key] = e.type === "keydown";
    };

    // Mouse movement
    this.input.on("pointermove", (pointer) => {
      if (!this.sceneChanging) {
        this.players[0].keyboardUsed = false;

        var distance = distanceBetweenTwoPoints(
          this.players[0].sprite.x,
          this.players[0].sprite.y,
          pointer.worldX,
          pointer.worldY
        );

        if (distance < 48) {
          this.setVelocityXY(0);
        } else {
          this.physics.moveTo(
            this.players[0].sprite,
            pointer.worldX,
            pointer.worldY,
            this.mainPlayerSpeed *
              Math.min(distance / this.controlZoneMaxDistance, 1)
          );

          this.physics.moveTo(
            this.players[0].circle,
            pointer.worldX,
            pointer.worldY,
            this.mainPlayerSpeed *
              Math.min(distance / this.controlZoneMaxDistance, 1)
          );
        }
      }
    });

    //stop movement when leaving window
    this.input.on("gameout", () => {
      if (!this.sceneChanging) {
        var vX = this.players[0].sprite.body.velocity.x;
        var vY = this.players[0].sprite.body.velocity.y;

        this.time.addEvent({
          delay: 100,
          callback: () => {
            this.setVelocityX(vX * 0.75);
            this.setVelocityY(vY * 0.75);
          },
        });

        this.time.addEvent({
          delay: 200,
          callback: () => {
            this.setVelocityX(vX * 0.5);
            this.setVelocityY(vY * 0.5);
          },
        });

        this.time.addEvent({
          delay: 300,
          callback: () => {
            this.setVelocityX(vX * 0.25);
            this.setVelocityY(vY * 0.25);
          },
        });

        this.time.addEvent({
          delay: 500,
          callback: () => {
            this.setVelocityXY(0);
          },
        });
      }
    });
  }

  loadAI() {
    var playersAI = [
      {
        blob: new BlobAI(
          "cherry",
          [this.worldWidth / 2 - 850, this.worldHeight / 2 + 250],
          "Bot 1",
          this.aiSpeedDt
        ),
        sprite: null,
        trailGraphic: null,
        claimedAreaGraphic: null,
        trailColour: 0x99cb99,
        claimedAreaColour: 0x99cb99,
      },
      {
        blob: new BlobAI(
          "banana",
          [this.worldWidth / 2, this.worldHeight / 2 + 450],
          "Bot 2",
          this.aiSpeedDt
        ),
        sprite: null,
        trailGraphic: null,
        claimedAreaGraphic: null,
        trailColour: 0x9999cb,
        claimedAreaColour: 0x9999cb,
      },
      {
        blob: new BlobAI(
          "kiwi",
          [this.worldWidth / 2 + 850, this.worldHeight / 2 + 250],
          "Bot 3",
          this.aiSpeedDt
        ),
        sprite: null,
        trailGraphic: null,
        claimedAreaGraphic: null,
        trailColour: 0xcb9999,
        claimedAreaColour: 0xcb9999,
      },
      {
        blob: new BlobAI(
          "grapes",
          [this.worldWidth / 2 - 850, this.worldHeight / 2 - 250],
          "Bot 4",
          this.aiSpeedDt
        ),
        sprite: null,
        trailGraphic: null,
        claimedAreaGraphic: null,
        trailColour: 0xfc5203,
        claimedAreaColour: 0xfc5203,
      },
      {
        blob: new BlobAI(
          "pear",
          [this.worldWidth / 2 + 850, this.worldHeight / 2 - 250],
          "Bot 5",
          this.aiSpeedDt
        ),
        sprite: null,
        trailGraphic: null,
        claimedAreaGraphic: null,
        trailColour: 0xfcec03,
        claimedAreaColour: 0xfcec03,
      },
      {
        blob: new BlobAI(
          "mango",
          [this.worldWidth / 2, this.worldHeight / 2 - 450],
          "Bot 6",
          this.aiSpeedDt
        ),
        sprite: null,
        trailGraphic: null,
        claimedAreaGraphic: null,
        trailColour: 0x034afc,
        claimedAreaColour: 0x034afc,
      },
    ];

    //for (var i = 0; i < 1; i++){
    for (var i = 0; i < this.numberOfAIplayers; i++) {
      playersAI[i].sprite = this.physics.add.sprite(
        playersAI[i].blob.position[0],
        playersAI[i].blob.position[1],
        playersAI[i].blob.icon
      );
      playersAI[i].trailGraphic = this.add.graphics();
      playersAI[i].claimedAreaGraphic = this.add.graphics();
      this.players.push(playersAI[i]);
    }

    this.players
      .filter((player) => player.blob.isAI === true)
      .forEach((player) => {
        player.blob.speedDt = 0.015;
        player.sprite.displayWidth = 50;
        player.sprite.scaleY = player.sprite.scaleX;
        player.sprite.setCollideWorldBounds(true);
        player.sprite.depth = 15;
        player.trailGraphic.depth = 14;
        player.claimedAreaGraphic.depth = 13;
      });
  }

  AIThink() {
    this.players
      .filter((player) => player.blob.isAI === true)
      .forEach((player) => {
        if (player.blob.isAlive) {
          player.blob.AIThink(player.sprite);

          // Sprite Rotation
          player.sprite.angle += 1;

          // Update player position
          player.blob.position = [player.sprite.x, player.sprite.y];

          // Trail and area graphics AI
          player.trailGraphic.clear();
          player.trailGraphic.lineStyle(
            this.trailWidth,
            player.trailColour,
            0.5
          );
          player.blob.path.draw(player.trailGraphic);
          player.blob.path.lineTo(
            player.blob.position[0],
            player.blob.position[1]
          );
          player.blob.trailAddPoint();

          player.claimedAreaGraphic.clear();
          player.claimedAreaGraphic.lineStyle(5, player.claimedAreaColour, 1.0);
          player.claimedAreaGraphic.fillStyle(player.claimedAreaColour, 1.0);
          for (var poly of player.blob.claimedArea) {
            player.claimedAreaGraphic.fillPoints(poly.points, true);
          }

          if (player.blob.trailPoints.length > 3) {
            this.mainPlayerSpeed;
            player.blob.checkClaimArea(this, player);
          }
        }
      });
  }

  checkPlayers() {
    var data = {
      mainPlayerName: this.dataFromWelcome.mainPlayerName,
      players: this.players,
      totalArea: this.totalArea,
      gameMusic: this.dataFromWelcome.gameMusic,
      gameSounds: this.dataFromWelcome.gameSounds,
      playersAI: this.dataFromWelcome.playersAI,
      difficulty: this.dataFromWelcome.difficulty,
    };

    if (!this.players[0].blob.isAlive) {
      this.gameEnded = true;
      this.sceneChanging = true;
      this.players[0].circle.destroy();
      this.time.addEvent({
        delay: 900,
        callback: () => {
          this.sounds.background.stop();
          this.scene.stop("scoresSceneAI");
          this.scene.stop("mainMenuScene");
          this.scene.start("endSceneAI", { ...data, win: false });
        },
      });
    }

    var numberOfPlayers = this.players.length;
    var deadCounter = 0;

    // start at 1, skip main player
    for (var i = 1; i < this.players.length; i++) {
      if (!this.players[i].blob.isAlive) {
        deadCounter++;
      }
    }

    if (deadCounter === numberOfPlayers - 1) {
      this.gameEnded = true;
      this.sceneChanging = true;
      this.time.addEvent({
        delay: 900,
        callback: () => {
          this.sounds.background.stop();
          this.scene.stop("scoresSceneAI");
          this.scene.stop("mainMenuScene");
          this.scene.start("endSceneAI", { ...data, win: true });
        },
      });
    }
  }

  updateMainPlayerGraphics() {
    // Trail and area graphics

    this.players[0].trailGraphic.clear();
    this.players[0].trailGraphic.lineStyle(
      this.trailWidth,
      this.players[0].trailColour,
      0.5
    );
    this.players[0].blob.path.draw(this.players[0].trailGraphic);
    this.players[0].blob.path.lineTo(
      this.players[0].sprite.x,
      this.players[0].sprite.y
    );
    this.players[0].blob.trailAddPoint();

    this.players[0].claimedAreaGraphic.clear();
    this.players[0].claimedAreaGraphic.lineStyle(
      5,
      this.players[0].claimedAreaColour,
      1.0
    );
    this.players[0].claimedAreaGraphic.fillStyle(
      this.players[0].claimedAreaColour,
      1.0
    );
    for (var poly of this.players[0].blob.claimedArea) {
      this.players[0].claimedAreaGraphic.fillPoints(poly.points, true);
    }

    if (this.players[0].blob.trailPoints.length > 3) {
      var claimed = this.players[0].blob.checkClaimArea(this, this.players[0]);
      if (claimed && this.dataFromWelcome.gameSounds) {
        this.sounds.claim.play();
      }
    }
  }

  killTest() {
    for (var player of this.players) {
      player.blob.killTest(this);
      if (!player.blob.isAlive && !player.blob.killAnimationComplete) {
        // clear graphics
        player.trailGraphic.clear();
        player.claimedAreaGraphic.clear();

        // if main player stop following
        if (!player.blob.isAI) {
          this.cameras.main.stopFollow();
        }

        // kill animation
        player.sprite.displayWidth += 20;
        player.sprite.scaleY = player.sprite.scaleX;
        player.sprite.alpha -= 0.015;
        player.sprite.angle -= 10;

        if (!player.blob.killSoundPlayed && this.dataFromWelcome.gameSounds) {
          this.sounds.kill.play();
          player.blob.killSoundPlayed = true;
        }

        player.blob.killDt += 1;

        if (player.blob.killDt > 100) {
          player.blob.killAnimationComplete = true;
          player.sprite.destroy();
        }
      }
    }
  }

  checkMovement() {
    if (this.players[0].keyboardUsed) {
      this.setVelocityXY(0);
    }

    if (this.keyMap["a"] || this.keyMap["ArrowLeft"]) {
      this.controlZoneDt++;
      this.players[0].keyboardUsed = true;
      this.setVelocityX(
        (-this.mainPlayerSpeed + this.speedOffset) *
          Math.min(1, this.controlZoneDt / this.controlZoneMaxTime)
      );
    } else if (this.keyMap["d"] || this.keyMap["ArrowRight"]) {
      this.controlZoneDt++;
      this.players[0].keyboardUsed = true;
      this.setVelocityX(
        (this.mainPlayerSpeed - this.speedOffset) *
          Math.min(1, this.controlZoneDt / this.controlZoneMaxTime)
      );
    }

    if (this.keyMap["w"] || this.keyMap["ArrowUp"]) {
      this.controlZoneDt++;
      this.players[0].keyboardUsed = true;
      this.setVelocityY(
        (-this.mainPlayerSpeed + this.speedOffset) *
          Math.min(1, this.controlZoneDt / this.controlZoneMaxTime)
      );
    } else if (this.keyMap["s"] || this.keyMap["ArrowDown"]) {
      this.controlZoneDt++;
      this.players[0].keyboardUsed = true;
      this.setVelocityY(
        (this.mainPlayerSpeed - this.speedOffset) *
          Math.min(1, this.controlZoneDt / this.controlZoneMaxTime)
      );
    }

    if (
      !this.keyMap["a"] &&
      !this.keyMap["ArrowLeft"] &&
      !this.keyMap["d"] &&
      !this.keyMap["ArrowRight"] &&
      !this.keyMap["w"] &&
      !this.keyMap["ArrowUp"] &&
      !this.keyMap["s"] &&
      !this.keyMap["ArrowDown"]
    ) {
      this.controlZoneDt = 1;
    }

    // Sprite Rotation
    this.players[0].sprite.angle += 1;

    // Update player position
    this.players[0].blob.position = [
      this.players[0].sprite.x,
      this.players[0].sprite.y,
    ];
  }

  setVelocityXY(velocity: number) {
    this.players[0].sprite.setVelocity(velocity);
    if (this.players[0].circle.body) {
      this.players[0].circle.body.velocity.x = velocity;
      this.players[0].circle.body.velocity.y = velocity;
    }
  }

  setVelocityY(velocity: number) {
    this.players[0].sprite.setVelocityY(velocity);
    if (this.players[0].circle.body) {
      this.players[0].circle.body.velocity.y = velocity;
    }
  }

  setVelocityX(velocity: number) {
    if (this.players[0].circle.body) {
      this.players[0].sprite.setVelocityX(velocity);
      this.players[0].circle.body.velocity.x = velocity;
    }
  }

  clearScene() {
    this.players = [];
    this.scoresheet = "";
    this.gameEnded = false;
    this.sceneChanging = false;
  }

  getSounds() {
    return {
      gameSounds: this.dataFromWelcome.gameSounds,
      gameMusic: this.dataFromWelcome.gameMusic,
    };
  }

  toggleGameSound() {
    this.dataFromWelcome.gameSounds = !this.dataFromWelcome.gameSounds;
    window.localStorage.setItem("gameSound", this.dataFromWelcome.gameSound);
  }

  toggleGameMusic() {
    if (this.dataFromWelcome.gameMusic) {
      this.dataFromWelcome.gameMusic = false;
      this.sounds.background.stop();
    } else {
      this.dataFromWelcome.gameMusic = true;
      this.sounds.background.play();
    }
    window.localStorage.setItem("gameMusic", this.dataFromWelcome.gameMusic);
  }

  forceEndScene() {
    var gameData = {
      mainPlayerName: this.dataFromWelcome.mainPlayerName,
      players: this.players,
      totalArea: this.totalArea,
      gameMusic: this.dataFromWelcome.gameMusic,
      gameSounds: this.dataFromWelcome.gameSounds,
      playersAI: this.dataFromWelcome.playersAI,
    };

    this.time.addEvent({
      delay: 200,
      callback: () => {
        this.sounds.background.stop();
        this.scene.stop("scoresSceneAI");
        this.scene.stop("mainMenuScene");
        this.scene.start("endSceneAI", { ...gameData, win: false });
      },
    });
  }
}
