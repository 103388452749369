import Phaser from "phaser";
// import { SingleAIScene } from "./SingleAIScene";
import { TestScene } from "./TestScene";
// import { MultipleAIScene } from "./MultipleAIScene";
import { ScoresSceneAI } from "./ScoresSceneAI";
import { WelcomeScene } from "./WelcomeScene";
import { EndSceneAI } from "./EndSceneAI";
import { MainSceneAI } from "./MainSceneAI";
import { MainSceneHuman } from "./MainSceneHuman";
import { Firebase } from "./Firebase";
import { ScoresSceneHuman } from "./ScoresSceneHuman";
import { EndSceneHuman } from "./EndSceneHuman";
import { PreloadScene } from "./PreloadScene";
import { MainMenuScene } from "./MainMenuScene";
import { CookieScene } from "./CookieScene";
import { GAME_THEME } from "./config/themes";

// Also change Welcome.css and End.css containers
//export var ratio = window.innerWidth / window.innerHeight;

export var gameWidth = 1280;
export var gameHeight = 720;
export var worldWidth = 3000;
export var worldHeight = 3000;

export var mainSceneHuman = new MainSceneHuman(gameWidth, gameHeight);
export var scoresSceneHuman = new ScoresSceneHuman();
export var endSceneHuman = new EndSceneHuman();

export var mainSceneAI = new MainSceneAI();
export var scoresSceneAI = new ScoresSceneAI();
export var endSceneAI = new EndSceneAI();

export var welcomeScene = new WelcomeScene();
export var preloadScene = new PreloadScene();

export var mainMenuScene = new MainMenuScene();
export var cookieScene = new CookieScene();

var month = new Date().getMonth() + 1;
var day = new Date().getDate();

export var game_theme = GAME_THEME.default;

if (month == 10) {
  game_theme = GAME_THEME.hallowean;
} else if (month == 12 || (month == 11 && day > 14)) {
  game_theme = GAME_THEME.christmas;
}

// config for phaser game
export var config = {
  type: Phaser.AUTO,
  parent: "004-snake",
  physics: {
    default: "arcade",
  },
  scene: [
    cookieScene,
    preloadScene,
    welcomeScene,
    mainSceneAI,
    scoresSceneAI,
    endSceneAI,
    mainMenuScene,
    mainSceneHuman,
    scoresSceneHuman,
    endSceneHuman,
  ],
  scale: {
    mode: Phaser.Scale.RESIZE,
    autoCenter: Phaser.Scale.CENTER_BOTH,
    width: gameWidth,
    height: gameHeight,
  },
  backgroundColor: "#38274a",
};

// Phaser game
export var game = new Phaser.Game(config);

var firebase = new Firebase();
firebase.initialise();
