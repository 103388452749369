import * as turf from "@turf/turf";
import { isStraightLine, squareUnion, removeHoles } from "./Utils";

export class Blob {
  id;
  name;
  icon: string;
  path: Phaser.Curves.Path;
  position: [number, number];
  trailPoints = [];
  claimedArea = [];
  claimedAreaTurf;
  firstArea: Boolean = false;
  leftClaimArea: Boolean = true;
  isAlive = true;
  isAi = false;

  killedBy;
  killDt = 0;
  killAnimationComplete = false;
  showPlayerScore = true;
  score = 0;
  finalScore;
  pointWithin;
  killSoundPlayed = false;
  firstUpdate = false;
  isMain = false;

  constructor(icon, position, name) {
    this.name = name;
    this.icon = icon;
    this.position = position;
    this.trailPoints = [position];
    this.path = new Phaser.Curves.Path(position[0], position[1]);
  }

  trailAddPoint() {
    if (this.trailPoints.length === 0) {
      this.trailPoints.push(this.position);
      return;
    }
    var lastPoint = this.trailPoints[this.trailPoints.length - 1];
    var dis = turf.distance(
      turf.point([lastPoint[0], lastPoint[1]]),
      turf.point(this.position)
    );
    if (dis > 500) {
      this.trailPoints.push(this.position);
      return;
    }
    if (
      lastPoint[0] === this.position[0] ||
      lastPoint[1] === this.position[1]
    ) {
      return;
    }
    this.trailPoints.push(this.position);
  }

  checkClaimArea(scene, player) {
    var claimed = false;

    if (!this.firstArea) {
      var trailLine = turf.lineString(this.trailPoints);
      var kinks = turf.kinks(trailLine).features;
      if (kinks.length > 0) {
        var trailPointsClosed = this.trailPoints;
        trailPointsClosed.push(this.trailPoints[0]);

        try {
          this.claimedAreaTurf = turf.polygon([trailPointsClosed]);
          this.generatePolygon();
          // Reset trail
          this.trailPoints = [];
          this.path = new Phaser.Curves.Path(
            this.position[0],
            this.position[1]
          );
        } catch (e) {
          console.log(e.message);
        }
        this.firstArea = true;
        claimed = true;
      }
    } else {
      var point = turf.point(this.position);
      if (!this.claimedAreaTurf) {
        return claimed;
      }
      this.pointWithin = turf.booleanPointInPolygon(
        point,
        this.claimedAreaTurf
      );

      // Check if blob has left claimed area
      if (!this.leftClaimArea && !this.pointWithin) {
        this.leftClaimArea = true;
        return claimed;
      }
      // Check if blob is still in claimed area and update trail
      else if (!this.leftClaimArea && this.pointWithin) {
        this.trailPoints = [];
        this.path = new Phaser.Curves.Path(this.position[0], this.position[1]);
        this.trailPoints.push(this.position);
      }

      // Check if blob intersects claimed area and merge
      else if (this.leftClaimArea && this.pointWithin) {
        this.trailPoints.push(this.position);
        this.leftClaimArea = false;

        // Alternate to concave - minimises holes - add centroid point or latter
        var trailPointsClosed = this.trailPoints;

        var claimAreaPoly;

        try {
          var centroid = this.getCentroidPoint();

          // If trail points is a straight line then add centroid point so turf is able to make a polygon
          if (isStraightLine(trailPointsClosed)) {
            trailPointsClosed.push(centroid);
          }

          trailPointsClosed.push(this.trailPoints[0]);

          claimAreaPoly = turf.polygon([trailPointsClosed]);
          var kinksPoly = turf.kinks(claimAreaPoly);

          // start point square union
          claimAreaPoly = squareUnion(this.trailPoints[0], claimAreaPoly);

          kinksPoly.features.forEach((kink) => {
            // intersection point square union
            claimAreaPoly = squareUnion(
              kink.geometry.coordinates,
              claimAreaPoly
            );
          });

          // End point square union
          claimAreaPoly = squareUnion(
            point.geometry.coordinates,
            claimAreaPoly
          );

          claimAreaPoly = turf.cleanCoords(claimAreaPoly);
          this.claimedAreaTurf = turf.union(
            claimAreaPoly,
            this.claimedAreaTurf
          );
          this.claimedAreaTurf = removeHoles(this.claimedAreaTurf);

          this.generatePolygon();
          claimed = true;
        } catch (e) {
          console.log(e);
        }
      }
    }

    try {
      for (var player of scene.players) {
        if (player.blob !== this) {
          if (this.claimedAreaTurf && player.blob.claimedAreaTurf) {
            if (
              turf.intersect(this.claimedAreaTurf, player.blob.claimedAreaTurf)
            ) {
              var thisClaimedArea = this.claimedAreaTurf;
              var playerClaimedArea = player.blob.claimedAreaTurf;

              var difference = turf.difference(
                playerClaimedArea,
                thisClaimedArea
              );
              if (difference) {
                player.blob.clearClaimedArea();
                player.blob.claimedAreaTurf = difference;
                player.blob.generatePolygon();
              }
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }

    return claimed;
  }

  killTest(scene) {
    for (var player of scene.players) {
      if (player.blob !== this && player.blob.isAlive && this.isAlive) {
        if (this.trailPoints.length < 2) {
          break;
        }
        if (player.blob.trailPoints.length < 2) {
          continue;
        }
        var line1 = turf.lineString(this.trailPoints);
        var line2 = turf.lineString(player.blob.trailPoints);

        var intersections = turf.lineIntersect(line1, line2).features;

        if (intersections.length > 0) {
          var intersection = intersections[0];

          var thisDist = turf.distance(turf.point(this.position), intersection);
          var playerDist = turf.distance(
            turf.point(player.blob.position),
            intersection
          );

          // sprite collision -> Kill both
          if (Math.abs(thisDist - playerDist) < 1000) {
            this.killSelf();
            this.killedBy = player.blob.name;
            player.blob.killSelf();
            player.blob.killedBy = this.name;
          } else if (thisDist > playerDist) {
            this.killSelf();
            this.killedBy = player.blob.name;
          } else {
            player.blob.killSelf();
            player.blob.killedBy = this.name;
          }
        }
      }
    }
  }

  generatePolygon() {
    var coords = turf.getCoords(this.claimedAreaTurf);
    this.claimedArea = [];
    if (this.claimedAreaTurf.geometry.type === "Polygon") {
      this.claimedArea.push(new Phaser.Geom.Polygon(coords[0]));
    } else if (this.claimedAreaTurf.geometry.type === "MultiPolygon") {
      for (var i in coords) {
        this.claimedArea.push(new Phaser.Geom.Polygon(coords[i][0]));
      }
    }
  }

  updateClaimedArea(coords) {
    // reset claimed area
    this.claimedArea = [];
    for (let poly of coords) {
      this.claimedArea.push(new Phaser.Geom.Polygon(poly));
    }
  }

  disposeTrail(pos?) {
    // reset trail
    if (pos || pos.length !== 0) {
      this.trailPoints = [pos];
      this.path = new Phaser.Curves.Path(pos[0], pos[1]);
    } else {
      this.trailPoints = [];
      this.path = new Phaser.Curves.Path(this.position[0], this.position[1]);
    }
  }

  clearClaimedArea() {
    this.claimedAreaTurf = null;
  }

  killSelf() {
    this.finalScore = this.calculateArea();
    this.isAlive = false;
    this.trailPoints = [];
    this.claimedArea = [];
    this.claimedAreaTurf = null;
  }

  getCentroidPoint() {
    const area = this.claimedAreaTurf;
    const turfPoint = turf.centroid(area);
    return turfPoint.geometry.coordinates;
  }

  calculateArea() {
    var total = 0;
    for (var poly of this.claimedArea) {
      total += poly.area;
    }
    return Math.abs(Math.round(total));
  }

  updateScore() {
    this.score = this.calculateArea();
  }

  updateClaimedAreaTurf() {
    for (var poly of this.claimedArea) {
      var newPoly;
      var points = poly.points.map(({ x, y }) => [x, y]);

      if (!this.claimedAreaTurf) {
        newPoly = turf.polygon([points]);
        this.claimedAreaTurf = newPoly;
      } else {
        newPoly = turf.polygon([points]);
        this.claimedAreaTurf = turf.union(newPoly, this.claimedAreaTurf);
      }
    }
  }
}
