import { Scene } from "phaser";
import { mainSceneAI } from "./start";
import { abbreviateNumber } from "./Utils";

export class ScoresSceneAI extends Scene {
  scoresheet;
  injectTo;
  injected = false;

  constructor() {
    super("scoresSceneAI");
  }

  preload() {
    this.load.html("highscore", "html/Scoresheet.html");
  }

  async create() {
    this.injectTo = document.getElementById("injectToScores");
    this.injected = false;
    await fetch("html/Scoresheet.html")
      .then((response) => response.text())
      .then((text) => {
        this.injectTo.innerHTML = text;
        this.injectTo.style.display = "flex";
        this.injectTo.style.tranform = "scale(1)";
        document.getElementById("highscore").style.visibility = "visible";
        this.injected = true;
      });
    this.events.on("shutdown", this.onStop);
  }

  update() {
    if (this.injected) {
      this.updateScores();
    }
  }

  updateScores() {
    var sortedPlayers = [];
    mainSceneAI.players.forEach((player) => {
      sortedPlayers.push({
        name: player.blob.name,
        area: player.blob.calculateArea(),
        killedBy: player.blob.killedBy,
        blob: player.blob,
      });
    });

    // Sort => player with biggest area is first
    // var playersKilled = sortedPlayers.filter((player) => player.killedBy);
    // var playersAlive = sortedPlayers.filter((player) => !player.killedBy);
    // playersAlive = playersAlive.sort((a, b) => (a.area < b.area ? 1 : -1));
    // sortedPlayers = playersAlive.concat(playersKilled);
    sortedPlayers = sortedPlayers.sort((a, b) => (a.area < b.area ? 1 : -1));

    var text = '<div id="scoreBox">';

    text += `<h3 id="heading">Fruit Loop</h3>`;
    sortedPlayers.forEach((player) => {
      var progress = (player.area / mainSceneAI.totalArea) * 100;

      if (player.killedBy) {
        text += `<div id="player">
                            <div id="${
                              player.blob.isAI ? "progress" : "progressMain"
                            }" style="width:${progress}%"> </div>
                            <div id="textContainer">
                                <span id="${
                                  player.blob.isAI ? "text" : "textMain"
                                }">${player.name}${"   -   Killed by "}${
          player.killedBy
        }</span>
                            </div>
                        </div>`;
      } else {
        var abbreviated = abbreviateNumber(player.area);
        text += `<div id="player">
                            <div id="${
                              player.blob.isAI ? "progress" : "progressMain"
                            }" style="width:${progress}%"></div>
                            <div id="textContainer">
                                <span id="${
                                  player.blob.isAI ? "text" : "textMain"
                                }">${
          player.name
        }${"   -   "}${abbreviated}</span>
                            </div>
                        </div>`;
      }
    });

    text += `</div>`;

    document.querySelector("#highscore").innerHTML = text;
  }

  onStop() {
    var doc = document.getElementById("highscore");
    doc.style.visibility = "hidden";
    doc.style.transform = "translateX(-200px)";
  }
}
