import concaveman from "concaveman";
import * as turf from "@turf/turf";
import hull from "hull.js";
import { concaveHull } from "@markroland/concave-hull/src/concaveHull";
import stats from "./Stats";
import unirand from "unirand";

// get rightmost point of polygon
export function getRightMostPointOfPoly(polygon) {
  var points = convertPolyToPoints(polygon);
  var rightMost = [0, 0];

  for (var i = 0; i < points.length; i++) {
    if (points[i][0] > rightMost[0]) {
      rightMost = points[i];
    }
  }

  return rightMost;
}

// get leftmost point of polygon
export function getLeftMostPointOfPoly(polygon) {
  var points = convertPolyToPoints(polygon);
  var leftMost = [9999, 9999];

  for (var i = 0; i < points.length; i++) {
    if (points[i][0] < leftMost[0]) {
      leftMost = points[i];
    }
  }
  return leftMost;
}

// get topmost point of polygon
export function getTopMostPointOfPoly(polygon) {
  var points = convertPolyToPoints(polygon);
  var topMost = [9999, 9999];

  for (var i = 0; i < points.length; i++) {
    if (points[i][1] < topMost[1]) {
      topMost = points[i];
    }
  }
  return topMost;
}

// get topmost point of polygon
export function getBottomMostPointOfPoly(polygon) {
  var points = convertPolyToPoints(polygon);
  var bottomMost = [0, 0];

  for (var i = 0; i < points.length; i++) {
    if (points[i][1] > bottomMost[1]) {
      bottomMost = points[i];
    }
  }
  return bottomMost;
}

// convert polygon to array of points [[x,y],[x,y]]
export function convertPolyToPoints(polygon) {
  var coords = turf.getCoords(polygon);
  var points = [];
  if (polygon.geometry.type === "Polygon") {
    points = coords[0];
  } else if (polygon.geometry.type === "MultiPolygon") {
    for (var i in coords) {
      var polygon = coords[i][0];
      polygon.forEach((point) => {
        points.push(point);
      });
    }
  }
  return points;
}

// convert polygon to FeatureCollection<Point>
export function convertPolyToFeatureCollectionPoints(polygon) {
  return turf.explode(polygon);
}

// convert array of points [[x,y], [x,y]]] to featurecollection
export function convertPoints(points) {
  var turfPoints = [];
  points.forEach((point) => {
    turfPoints.push(turf.point(point));
  });
  return turf.featureCollection(turfPoints);
}

// turf concave - takes in featurecollection of points
export function turfConcave(points) {
  return turf.concave(points);
}

//closest to what we want - takes in feature collection of points
export function turfConcaveman(points) {
  const coordinates = points.features.map((f) => f.geometry.coordinates);
  return turf.polygon([concaveman(coordinates, 0.9)]);
}

//weird shapes - takes in array of points
export function turfHull(points: any) {
  const hullCoords = hull(points, 20);
  return turf.polygon([hullCoords]);
}

//slow - takes in array of points
export function turfMark(points: any) {
  const hullCoords = concaveHull.calculate(points, 3);
  return turf.polygon(hullCoords);
}

export function getRandomBezierPath(position, boundx, boundY) {
  // Pick a default angle
  let random_angle = stats.chooseRandomAngle();

  // Pick a default length between 3 (inclusive) and 20 (exclusive)
  let random_distance = stats.chooseUniform(180, 220);

  // Choose a second random angle near the first one
  // let angle2 = stats.chooseUniform(random_angle - )
  let rng = unirand.normal(random_angle, stats.π * 0.75);
  let random_angle2 = rng.randomSync();
  let random_dist2 = stats.chooseUniform(110, 130);

  // var x = 500, y = 500, z = 0;
  let x = position[0];
  let y = position[1];

  let p1 = stats.pointFromVector({ x: x, y: y }, random_angle, random_distance);
  let p2 = stats.pointFromVector(p1, random_angle2, random_dist2);

  // while (p1.x <= 0 || p1.x >= boundx){
  //     p1 = stats.pointFromVector({x: x, y: y}, -random_angle, random_distance);
  // }

  // while (p2.x <= 0 || p2.y >= boundY){
  //     p2 = stats.pointFromVector(p1, -random_angle2, random_dist2);
  // }

  var startPoint = new Phaser.Math.Vector2(x, y);
  var controlPoint1 = new Phaser.Math.Vector2(p1.x, p1.y);
  var endPoint = new Phaser.Math.Vector2(p2.x, p2.y);

  return new Phaser.Curves.QuadraticBezier(startPoint, controlPoint1, endPoint);
}

export function getStraightPath(startPosition, endPosition) {
  var curve = new Phaser.Curves.Line(
    new Phaser.Math.Vector2(startPosition[0], startPosition[1]),
    new Phaser.Math.Vector2(endPosition[0], endPosition[1])
  );
  return curve;
}

export function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

// Points in the form [x,y]
export function makeSquarePoly(point) {
  var x = point[0];
  var y = point[1];

  var squareData = [
    [x - 1, y - 1],
    [x + 1, y - 1],
    [x + 1, y + 1],
    [x - 1, y + 1],
    [x - 1, y - 1],
  ];

  return turf.polygon([squareData]);
}

// returns a union of a square polygon from point and polygon
export function squareUnion(point, polygon) {
  var squarePoly = makeSquarePoly(point);
  return turf.union(squarePoly, polygon);
}

// checks if an array of points is on a straight line
export function isStraightLine(trailPoints) {
  if (trailPoints.length === 0) {
    return false;
  }

  var x1 = trailPoints[0][0];
  var y1 = trailPoints[0][1];
  var slope1 = null;

  for (var i = 1; i < trailPoints.length; i++) {
    let x2 = trailPoints[i][0];
    let y2 = trailPoints[i][1];
    if (x2 - x1 === 0) {
      return false;
    }
    if (slope1 === null) {
      slope1 = (y2 - y1) / (x2 - x1);
      continue;
    }
    let slope2 = (y2 - y1) / (x2 - x1);
    if (slope2 != slope1) {
      return false;
    }
  }
  return true;
}

// removes holes from a turf polygon
export function removeHoles(polygon) {
  if (polygon.geometry.type === "MultiPolygon") {
    return polygon;
  }

  var coords = turf.getCoords(polygon);
  var newPoly = turf.polygon([coords[0]]);

  return newPoly;
}

export function abbreviateNumber(number) {
  var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  if (tier == 0) return number;

  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);
  var scaled = number / scale;

  return scaled.toFixed(1) + suffix;
}

//useful backoff functions
function wait(duration: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

export const backoff = async (fn, depth = 0, attempt = 0) => {
  try {
    var result = await fn();
    return result;
  } catch (e) {
    if (depth > 7) {
      console.error("Oh noez given up retrying to connect...");
      throw e;
    }
    var duration = 2 ** depth * 10;
    console.log(
      `Retrying connection attempt number ${attempt} after duration of ${duration}`
    );
    attempt++;
    await wait(duration);

    return backoff(fn, depth + 1, attempt);
  }
};

export const distanceBetweenTwoPoints = (x1, y1, x2, y2) => {
  var a = x1 - x2;
  var b = y1 - y2;

  return Math.sqrt(a * a + b * b);
};

//cookies
export const setCookie = (cname, cvalue, exdays = 7) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export var differenceInDates = function (d1, d2) {
  var diff = d1.getTime() - d2.getTime();
  return diff / (1000 * 60 * 60 * 24);
};
