import { Scene } from "phaser";
import { mainSceneHuman } from "./start";
import { abbreviateNumber } from "./Utils";

export class ScoresSceneHuman extends Scene {
  scoresheet;
  injectTo;
  injected = false;

  constructor() {
    super("scoresSceneHuman");
  }

  async create() {
    this.injectTo = document.getElementById("injectToScores");
    this.injected = false;
    await fetch("html/Scoresheet.html")
      .then((response) => response.text())
      .then((text) => {
        this.injectTo.innerHTML = text;
        this.injectTo.style.display = "flex";
        this.injectTo.style.tranform = "scale(1)";
        document.getElementById("highscore").style.visibility = "visible";
        this.injected = true;
      });
    this.events.on("shutdown", this.onStop);
  }

  update() {
    if (this.injected) {
      this.updateScores();
    }
  }

  updateScores() {
    var sortedPlayers = [];
    mainSceneHuman.players.forEach((player) => {
      sortedPlayers.push({
        name: player.blob.name,
        area: player.blob.score,
        killedBy: player.blob.killedBy,
        blob: player.blob,
      });
    });

    // Sort => player with biggest area is first
    //var playersKilled = sortedPlayers.filter((player) => player.killedBy);
    //var playersAlive = sortedPlayers.filter((player) => !player.killedBy);
    //playersAlive = playersAlive.sort((a, b) => (a.area < b.area ? 1 : -1));
    //playersKilled = playersKilled.sort((a, b) => (a.area < b.area ? 1 : -1));
    //sortedPlayers = playersAlive.concat(playersKilled);
    sortedPlayers = sortedPlayers.sort((a, b) => (a.area < b.area ? 1 : -1));

    var text = '<div id="scoreBox">';

    text += `<h3 id="heading">Fruit Loop</h3>`;
    sortedPlayers.forEach((player) => {
      var progress = (player.area / mainSceneHuman.totalArea) * 100;
      var abbreviated = abbreviateNumber(player.area);
      if (player.killedBy && player.blob.showPlayerScore) {
        text += `<div id="player">
                            <div id="${
                              player.blob.isMain ? "progressMain" : "progress"
                            }" style="width:${progress}%"> </div>
                            <div id="textContainer">
                                <span id="${
                                  player.blob.isMain ? "textMain" : "text"
                                }">${player.name}${
          "  -  " + abbreviated
        }${"   -   Killed by "}${player.killedBy}</span>
                            </div>
                        </div>`;
      } else if (player.blob.showPlayerScore) {
        text += `<div id="player">
                            <div id="${
                              player.blob.isMain ? "progressMain" : "progress"
                            }" style="width:${progress}%"></div>
                            <div id="textContainer">
                                <span id="${
                                  player.blob.isMain ? "textMain" : "text"
                                }">${
          player.name
        }${"   -   "}${abbreviated}</span>
                            </div>
                        </div>`;
      }
    });

    text += `</div>`;

    document.querySelector("#highscore").innerHTML = text;
  }

  onStop() {
    var doc = document.getElementById("highscore");
    doc.style.visibility = "hidden";
    doc.style.transform = "translateX(-200px)";
  }
}
